import { useApolloLazyQuery } from 'API/services/Apollo'

import {
  ClassifierSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'

import { ClassifiersPickerQuery } from './GraphQL'

export function usePickerClassifier() {
  const [
    loadClassifiers,
    { data, loading, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    QueryData<'classifiersByOffset'>,
    Gateway.QueryClassifiersByOffsetArgs
  >(ClassifiersPickerQuery, {
    variables: {
      sorting: [
        {
          field: ClassifierSortingField.Name,
          direction: SortingDirection.Asc,
        },
      ],
      paging: { limit: 15, offset: 0 },
    },
  })

  const classifiers = data?.classifiersByOffset?.rows ?? []

  async function refetch(inputValue: string) {
    const response = await innerRefetch?.({
      filter: { name: { like: inputValue } },
    })
    return response?.data.classifiersByOffset.rows ?? []
  }

  return {
    classifiers,
    loadClassifiers,
    loading,
    refetch,
  }
}
