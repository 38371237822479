import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { GroupsPicker } from 'components/blocks/__v3__/GroupsPicker'
import { Flex } from 'components/ui/__v2__/Grid'
import { InputLabel } from 'components/ui/__v3__'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'

type Props = {
  labelText: string
  required?: boolean
  width?: number
  isDisabled?: boolean
}

export function FormGroupsPicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required = false,
  ...rest
}: ControllerBaseProps<T> & Props) {
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <Flex flexDirection="column">
      <InputLabel>
        <LabelText labelText={labelText} required={required} />
      </InputLabel>
      <GroupsPicker
        isMulti
        isSearchable
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        {...rest}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}
