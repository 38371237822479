import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { CreateRulesetMutation } from './GraphQL'

export function useCreateRuleset() {
  const [innerCreate, { loading: creating }] = useApolloMutation<
    MutationData<'createRuleset'>,
    Gateway.MutationCreateRulesetArgs
  >(CreateRulesetMutation, { translationKey: 'ruleset.toasts.create' })

  const createRuleset = async (input: Gateway.CreateRulesetInput) => {
    const result = await innerCreate({
      variables: {
        input,
      },
    })
    const success = !graphQLResultHasError(result)

    return success
  }

  return { createRuleset, creating }
}
