import React from 'react'
import { components } from 'react-select'

import { Spinner } from 'components/ui/Spinner'

export const LoadingIndicator = () => <Spinner primary />

LoadingIndicator.propTypes = {
  ...components.LoadingIndicator.propTypes,
}
