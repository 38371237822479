import React, { useCallback, useMemo } from 'react'

import { WidthProps } from 'styled-system'

import { API } from 'API'

import { Select } from 'components/ui/__v3__/Select'

import { TimetypeOption } from './components'
import { timeTypeToOption } from './helpers'
import { TimePickerProps, TimeTypePickerOption } from './types'

type Props = WidthProps & TimePickerProps

export function TimeTypesPicker({
  value,
  isDisabled,
  onSelect,
  isMulti,
  ...rest
}: Props) {
  const { loadTimeTypes, timeTypes, fetching } = API.TimeTypes.picker()

  const handleMenuOpen = useCallback(() => loadTimeTypes(), [loadTimeTypes])

  const handleChange = isMulti
    ? (value: TimeTypePickerOption[]) => onSelect(value)
    : (value: TimeTypePickerOption | null) => {
        onSelect(value)
      }

  const defaultOptions = useMemo(() => timeTypes.map(timeTypeToOption), [
    timeTypes,
  ])

  const formatOptions = useCallback(
    (option: TimeTypePickerOption) => (
      <TimetypeOption color={option.color} label={option.label} />
    ),
    [],
  )

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect={!isMulti}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptions}
      isClearable
      isDisabled={isDisabled}
      isLoading={fetching}
      isSearchable
      maxMenuHeight={180}
      value={value}
      withPortal
      onChange={handleChange}
      onMenuOpen={handleMenuOpen}
      {...rest}
    />
  )
}
