import cond from 'lodash/cond'
import constant from 'lodash/constant'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import matches from 'lodash/matches'
import omit from 'lodash/omit'
import stubTrue from 'lodash/stubTrue'

import { areaIdsToFilters, areasToFilters } from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'
import Utils from 'services/Utils'

import { getCompanyId } from 'store/selectors/viewer'

export const LOAD_TASKS = createAsyncAction('employees/timeCards/LOAD_TASKS')
export const LOAD_EMPLOYEES = createAsyncAction(
  'employees/timeCards/LOAD_EMPLOYEES',
)
export const LOAD_EMPLOYEE = createAsyncAction(
  'employees/timeCards/LOAD_EMPLOYEE',
)
export const LOAD_EMPLOYEES_SILENT = createAsyncAction(
  'employees/timeCards/LOAD_EMPLOYEES_SILENT',
)
export const LOAD_MORE_EMPLOYEES = createAsyncAction(
  'employees/timeCards/LOAD_MORE_EMPLOYEES',
)
export const LOAD = createAsyncAction('employees/timeCards/LOAD')
export const LOAD_INTERSECTIONS = createAsyncAction(
  'employees/timeCards/LOAD_INTERSECTIONS',
)
export const ADD_NOTE = createAsyncAction('employees/timeCards/ADD_NOTE')
export const CLEAR_INTERSECTIONS = 'employees/timeCards/CLEAR_INTERSECTIONS'
export const CREATE = createAsyncAction('employees/timeCards/CREATE')
export const APPROVE = createAsyncAction('employees/timeCards/APPROVE')
export const UNAPPROVE = createAsyncAction('employees/timeCards/UNAPPROVE')
export const DISCARD = createAsyncAction('employees/timeCards/DISCARD')
export const ADJUST = createAsyncAction('employees/timeCards/ADJUST')
export const STOP = createAsyncAction('employees/timeCards/STOP')

export const CLEAR = 'employees/timeCards/CLEAR'
export const CLEAR_TASKS = 'employees/timeCards/CLEAR_TASKS'
export const LOAD_TIME_CARD = createAsyncAction(
  'employees/timeCards/LOAD_TIME_CARD',
)

const includeTaskTimeEntries = ['forms', 'task.comments', 'branch']

export const loadTimeEntriesTasks = ({
  filters,
  companyId,
  page,
} = {}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/task_time_entries`,
      types: LOAD_TASKS,
      query: {
        include: includeTaskTimeEntries.join(),
        display: 'all',
        filter: filters,
        company_id: companyId,
        page,
      },
      paged: true,
    }),
  )
}

const includeEmployeesSummary = [
  'employee.profile.profileAvatar',
  'employee.branch',
  'employee.activeTimeEntry.branch',
  'employee.activeTimeEntry.department',
]

const addNoteInclude = ['manager', 'timeEntry', 'pauseTimer']

export const addNote = (data, timeCardId) => dispatch => {
  return dispatch(
    apiCall({
      method: 'POST',
      types: ADD_NOTE,
      endpoint: `/time_entries/${timeCardId}/notes`,
      query: {
        data,
        include: addNoteInclude.join(),
      },
    }),
  )
}

export const loadEmployeesSummary = (
  {
    from,
    to,
    status = [],
    kind = '',
    areas = {},
    size = 10,
    employeeId = '',
    startingAfter = undefined,
    timeBucketParents,
    timeBucketChildren,
    page,
    sort,
  } = {},
  { loadMore, withLoading = true } = {},
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const statusFilter = isEmpty(status) ? {} : { state: { in: status } }
  const kindFilter = isEmpty(kind) ? {} : { kind: { eq: kind } }
  const employeeIdFilter = employeeId ? { employee_id: { eq: employeeId } } : {}
  const timeBucketFilter = Utils.TimeBucket.selectedTimeBucketsToJsonApiFilters(
    { timeBucketChildren, timeBucketParents },
  )

  const types = cond([
    [matches({ loadMore: true }), constant(LOAD_MORE_EMPLOYEES)],
    [matches({ withLoading: false }), constant(LOAD_EMPLOYEES_SILENT)],
    [stubTrue, constant(LOAD_EMPLOYEES)],
  ])({ withLoading, loadMore })

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/time_entries/employees_summary`,
      types,
      query: {
        include: includeEmployeesSummary.join(),
        filter: {
          start_on: {
            between: [from, to],
          },
          ...areasToFilters(areas),
          ...kindFilter,
          ...employeeIdFilter,
          ...statusFilter,
          ...timeBucketFilter,
        },
        page: {
          number: page ?? undefined,
          size,
          starting_after: startingAfter,
        },
        sort: sort ?? undefined,
      },
      paged: true,
    }),
  )
}

export const includeTimeEntries = [
  'employee.profile.profileAvatar',
  'employee.branch',
  'schedule.shift.pauses',
  'pauseTimers',
  'department',
  'branch',
  'job',
  'logs',
  'logs.manager',
  'logs.manager.user',
  'logs.manager.user.profile',
  'authorisedBy',
  'pauseTimers.notes',
  'statistics',
  'info',
  'info.recognitionPhoto',
  'timerNotes',
  'timerNotes.manager',
  'timerNotes.manager.user',
  'timerNotes.manager.user.profile',
  'user',
  'earningType',
  'timeBucket',
  'timeBucket.timeBucketParent',
]

export const loadTimeCard = timeCardId =>
  apiCall({
    endpoint: `/time_entries/${timeCardId}`,
    types: LOAD_TIME_CARD,
    query: {
      include: includeTimeEntries.join(),
    },
  })

export const loadTimeCards = ({
  from,
  to,
  employeeId,
  status = [],
  kind = '',
  areas = {},
  page,
  timeBucketParents,
  timeBucketChildren,
} = {}) => dispatch => {
  const employeeIdFilter = employeeId ? { employee_id: { eq: employeeId } } : {}
  const statusFilter = isEmpty(status) ? {} : { state: { in: status } }
  const kindFilter = isEmpty(kind) ? {} : { kind: { eq: kind } }

  const timeBucketFilter = Utils.TimeBucket.selectedTimeBucketsToJsonApiFilters(
    { timeBucketChildren, timeBucketParents },
  )

  return dispatch(
    apiCall({
      endpoint: `/time_entries`,
      types: LOAD,
      query: {
        include: includeTimeEntries.join(),
        filter: {
          start_on: {
            between: [from, to],
          },
          ...areasToFilters(areas),
          ...employeeIdFilter,
          ...statusFilter,
          ...kindFilter,
          ...timeBucketFilter,
        },
        page,
      },
      paged: true,
    }),
  )
}

export const updateTimeEntryEarningType = (timeCardId, earningTypeId) =>
  apiCall({
    endpoint: `/time_entries/${timeCardId}`,
    method: 'PUT',
    query: {
      data: {
        type: 'timeEntries',
        attributes: {
          earning_type_id: earningTypeId ?? null,
        },
      },
    },
  })

const loadEmployeesInclude = [
  'profile',
  'jobsEmployees.job',
  'jobs.departments',
  'jobs.branches',
  'branch',
]

export const loadCurrentEmployeeByDate = ({
  locationIds,
  departmentIds,
  noDispatch = false,
  ...filterData
} = {}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/employees`,
      types: !noDispatch && LOAD_EMPLOYEE,
      query: {
        include: loadEmployeesInclude.join(),
        filter: {
          ...filterData,
          ...areaIdsToFilters({ locationIds, departmentIds }),
        },
      },
    }),
  )
}

export const loadIntersections = ({ filters = {}, page } = {}) => {
  return apiCall({
    endpoint: `/employees/${filters.affectedEmployeeId}/overlapped_time_entries`,
    types: LOAD_INTERSECTIONS,
    query: {
      filter: omit(filters, 'affectedEmployeeId'),
      page,
    },
    paged: true,
  })
}

export const createTimeCard = timeEntry => {
  return apiCall({
    method: 'POST',
    endpoint: '/time_entries',
    query: {
      include: includeTimeEntries.join(),
      data: [
        {
          type: 'timeEntries',
          attributes: {
            startAt: timeEntry.startAt,
            endAt: timeEntry.endAt,
            pauses: timeEntry.pauses,
          },
          relationships: {
            employee: {
              data: {
                id: timeEntry.employeeId,
                type: 'employees',
              },
            },
            job: {
              data: {
                id: timeEntry.jobId,
                type: 'jobs',
              },
            },
          },
        },
      ],
    },
    types: CREATE,
  })
}

const actionsInclude = ['logs.manager.user']

export const approveTimeCard = (timeEntryIds, employeeId = null) => {
  return apiCall({
    method: 'POST',
    endpoint: '/time_entries/authorise',
    types: APPROVE,
    query: {
      data: map(timeEntryIds, id => ({ id, type: 'timeEntries' })),
      include: actionsInclude.join(),
    },
    payload: { timeEntryIds, employeeId },
  })
}

export const unapproveTimeCard = (
  timeEntryIds,
  employeeId = null,
  prevState,
) => {
  return apiCall({
    method: 'POST',
    endpoint: '/time_entries/restore',
    types: UNAPPROVE,
    query: {
      data: map(timeEntryIds, id => ({ id, type: 'timeEntries' })),
      include: actionsInclude.join(),
    },
    payload: {
      timeEntryIds,
      employeeId,
      prevState,
    },
  })
}

export const discardTimeCard = timeEntryIds => {
  return apiCall({
    method: 'POST',
    endpoint: '/time_entries/unauthorise',
    types: DISCARD,
    query: {
      data: map(timeEntryIds, id => ({ id, type: 'timeEntries' })),
      include: actionsInclude.join(),
    },
    payload: { timeEntryIds },
  })
}

export const stopTimeCard = timeEntryIds => {
  return apiCall({
    method: 'POST',
    endpoint: `/time_entries/${timeEntryIds[0]}/stop`,
    types: STOP,
    query: { include: actionsInclude.join() },
    payload: { timeEntryIds },
  })
}

// TODO: reduce this
export const includeSchedules = [
  'shifts_job.shift.pauses',
  'shifts_job.shift.shiftsJobs',
  'shifts_job.job.department',
  'employee',
  'shift_covers.accepted_employee',
  'shift_covers.from_employee',
  'shift_covers.to_employees',
  'shift_covers.schedule',
  'accepted_shift_trades.accepted_schedule',
  'accepted_shift_trades.from_schedule',
  'accepted_shift_trades.to_schedules',
  'from_shift_trades.accepted_schedule',
  'from_shift_trades.from_schedule',
  'from_shift_trades.to_schedules',
  'absences.employee',
  'absences.schedule',
  'timeEntry.info',
  'timeEntry.pauseTimers',
  'timeEntry.schedule',
  'timeEntry.pauseTimers.pause',
  'timeEntry.authorisedBy',
  'timeEntry.logs',
  'schedulesLogs',
  'timeEntry.logs.manager.user.profile',
  'timeEntry.logs.pauseTimer',
  'timeEntry.notes',
  'timeEntry.job',
  'schedulesLogs.manager.user.profile',
  'schedulesLogs.pauseTimer',
  'schedulesLogs.schedule',
  'schedulesLogs.timeEntry',
  'timeEntry.pauseTimers.notes',
  'timeEntry.statistics',
]

export const adjustTimeCard = scheduleIds => {
  return apiCall({
    method: 'POST',
    endpoint: '/schedules/adjust_timesheets',
    types: ADJUST,
    query: {
      include: includeSchedules.join(),
      data: map(scheduleIds, id => ({
        id,
        type: 'schedules',
      })),
    },
  })
}

export const clearTimeCards = () => ({
  type: CLEAR,
})

export const clearTimeCardTasks = () => ({
  type: CLEAR_TASKS,
})

export const clearIntersections = () => ({
  type: CLEAR_INTERSECTIONS,
})
