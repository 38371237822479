export interface Constraints {
  dailyShiftLimit?: number
  minRestTime?: number
  minOvernightRestTime?: number
  unavailabilityAsHardConstraint: boolean
}

export interface LaunchAutoAssignParams {
  attributes: {
    strategy: 'assign'
    startDate: string
    endDate: string
    constraints: Constraints
  }
  relationships: {
    branch: {
      data: {
        type: 'branches'
        id: string
      }
    }
    department: {
      data: {
        type: 'departments'
        id?: string
      }
    }
  }
}

export type AutoAssignQueryResult = {
  state: AutoAssignStatus
  result: 'success' | 'failure'
  failureReason?: string
}

export enum AutoAssignStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Finished = 'finished',
}
