import React from 'react'

import { Flex } from 'components/ui/__v2__/Grid'

import { Color } from './styles'

type Props = {
  color: string
  label: string
}

export function TimetypeOption({ label, color }: Props) {
  return (
    <Flex alignItems="center" gap={1}>
      <Color color={`#${color}`} />
      {label}
    </Flex>
  )
}
