import React, { useCallback } from 'react'

import { API } from 'API'

import { Select } from 'components/ui/__v3__/Select'

import { createpDebounce } from 'helpers/debounce'

import { i18n } from 'i18n'

import { customCVFieldToOption } from 'services/Options'

import { CustomCvFieldsPickerProps } from './types'

export function CustomCvFieldsPicker({
  value,
  filterOption = null,
  onSelect,
  isClearable = true,
  classifier,
  disabled,
}: CustomCvFieldsPickerProps) {
  const { loading, refetch, load, customFields } = API.CustomCvFields.picker({
    classifier,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadOptions = useCallback(
    createpDebounce(async (inputValue: string, callback: Function) => {
      const refetchedData = await refetch(inputValue)
      callback(refetchedData.map(customCVFieldToOption))
    }),
    [refetch],
  )

  return (
    <Select
      // TODO: remove ts-ignore when TS will be added to the Select
      // @ts-ignore
      async
      defaultOptions={customFields.map(customCVFieldToOption)}
      filterOption={filterOption}
      isClearable={isClearable}
      isDisabled={disabled}
      isLoading={loading}
      isSearchable
      loadOptions={handleLoadOptions}
      loadingMessage={() => i18n('common.loading')}
      placeholder={`${i18n('actions.select')}...`}
      value={value}
      withPortal
      onChange={onSelect}
      onMenuOpen={load}
    />
  )
}
