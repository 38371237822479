import {
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client'

import { useLocale } from 'hooks/Locale'
import { useAppContext } from 'hooks/useAppContext'

import { i18n, TranslationKeys } from 'i18n'

import { showToast, ToastMessage } from 'services/Toasts'

type MutationType = 'create' | 'update' | 'delete' | 'export'

type CommonOptions = {
  silent?: boolean
}
type DefaultMessages = {
  entity?: TranslationKeys
  mutationType?: MutationType
  translationKey?: undefined
}
type CustomMessages = {
  translationKey?: TranslationKeys
  entity?: undefined
  mutationType?: undefined
}

export type UseMutationOptions = CommonOptions &
  (DefaultMessages | CustomMessages)

export function useApolloMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & UseMutationOptions,
): MutationTuple<TData, TVariables> {
  const { company } = useAppContext()
  const locale = useLocale()

  const innerOptions = {
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
        'company-id': company.microserviceId,
        'core-company-id': company.coreId,
        'accept-language': locale,
      },
    },
  }

  if (options?.silent) {
    return useMutation(mutation, innerOptions)
  }

  return useMutation(mutation, {
    ...innerOptions,
    onError: error => {
      const message = getMessage(options, error.message)

      showToast({
        type: 'error',
        title: message.error.title,
        content: message.error.content,
      })
    },
    onCompleted: () => {
      const message = getMessage(options)

      showToast({
        type: 'success',
        title: message.success.title,
        content: message.success.content,
      })
    },
  })
}

interface AlertMessage {
  error: ToastMessage
  success: ToastMessage
}

function getMessage(
  options?: UseMutationOptions,
  serverErrorMessage?: string,
): AlertMessage {
  if (options?.translationKey) {
    return {
      error: {
        title: i18n(`${options.translationKey}.error.title` as TranslationKeys),
        content: i18n(
          `${options.translationKey}.error.message` as TranslationKeys,
        ),
      },
      success: {
        title: i18n(
          `${options.translationKey}.success.title` as TranslationKeys,
        ),
        content: i18n(
          `${options.translationKey}.success.message` as TranslationKeys,
        ),
      },
    }
  }

  if (options?.entity && options?.mutationType) {
    return {
      error: {
        title: i18n(
          `alertMessages.${options.mutationType}.error.title` as TranslationKeys,
        ),
        content: `${i18n(options.entity)} ${i18n(
          `alertMessages.${options.mutationType}.error.message` as TranslationKeys,
        )}`,
      },
      success: {
        title: i18n(
          `alertMessages.${options.mutationType}.success.title` as TranslationKeys,
        ),
        content: `${i18n(options.entity)} ${i18n(
          `alertMessages.${options.mutationType}.success.message` as TranslationKeys,
        )}`,
      },
    }
  }

  return {
    error: {
      title: i18n('alertMessages.default.error.title'),
      content:
        serverErrorMessage ?? i18n('alertMessages.default.error.message'),
    },
    success: {
      title: i18n('alertMessages.default.success.title'),
      content: i18n('alertMessages.default.success.message'),
    },
  }
}
