import snakeCase from 'lodash/snakeCase'

import { areaIdsToFilters, areasToFilters } from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'
import {
  accessLevelsToFilters,
  searchToFilters,
  statusesToFilters,
} from 'helpers/staff'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const INIT_LOCATIONS = createAsyncAction('areas/INIT_LOCATIONS')

export const LOAD_LOCATIONS = createAsyncAction('areas/LOAD_LOCATIONS')
export const CLEAR_LOCATIONS = 'areas/CLEAR_LOCATIONS'

export const INIT_DEPARTMENTS = createAsyncAction('areas/INIT_DEPARTMENTS')

export const LOAD_DEPARTMENTS = createAsyncAction('areas/LOAD_DEPARTMENTS')
export const CLEAR_DEPARTMENTS = 'areas/CLEAR_DEPARTMENTS'

export const INIT_JOBS = createAsyncAction('areas/INIT_JOBS')

export const LOAD_JOBS = createAsyncAction('areas/LOAD_JOBS')
export const CLEAR_JOBS = 'areas/CLEAR_JOBS'

export const INIT_EMPLOYEES = createAsyncAction('areas/INIT_EMPLOYEES')
export const LOAD_EMPLOYEES = createAsyncAction('areas/LOAD_EMPLOYEES')
export const CLEAR_EMPLOYEES = 'areas/CLEAR_EMPLOYEES'

export const INIT_STAFF = createAsyncAction('areas/INIT_STAFF')
export const LOAD_STAFF = createAsyncAction('areas/LOAD_STAFF')
export const CLEAR_STAFF = 'areas/CLEAR_STAFF'

// prettier-ignore
export const INIT_JOBS_EMPLOYEES = createAsyncAction('areas/INIT_JOBS_EMPLOYEES')
// prettier-ignore
export const LOAD_JOBS_EMPLOYEES = createAsyncAction('areas/LOAD_JOBS_EMPLOYEES')
export const CLEAR_JOBS_EMPLOYEES = 'areas/CLEAR_JOBS_EMPLOYEES'

export const CLEAR = 'areas/CLEAR'

const PAGE_SIZE = 10
const PAGE_NUMBER = 1
const STARTING_AFTER = undefined

const locationsCall = (
  types,
  {
    display,
    excludedManagerIds,
    ids = null,
    include,
    name = null,
    size = PAGE_SIZE,
    startingAfter = STARTING_AFTER,
    endingBefore,
    isEtfo = false,
  } = {
    size: PAGE_SIZE,
    number: PAGE_NUMBER,
  },
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const query = {
    filter: {},
    page: {
      size,
      starting_after: startingAfter,
      ending_before: endingBefore,
    },
  }

  if (ids) {
    query.filter.id = {
      in: ids,
    }
  } else if (name) {
    query.filter.name_or_location_code = name
  }

  if (excludedManagerIds) {
    query.filter.manager_id = {
      nin: excludedManagerIds,
    }
  }

  if (display) {
    query.display = snakeCase(display)
  }

  if (isEtfo) {
    query.sort = 'name'
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/branches`,
      types,
      query: {
        ...query,
        include,
      },
    }),
  )
}

export const initLocations = params => {
  return locationsCall(INIT_LOCATIONS, params)
}

export const loadLocations = params => {
  return locationsCall(LOAD_LOCATIONS, params)
}

export const clearLocations = () => ({
  type: CLEAR_LOCATIONS,
})

const departmentsCall = (
  types,
  {
    display,
    excludedManagerIds,
    include,
    locationIds = null,
    name = null,
    size = PAGE_SIZE,
    startingAfter = STARTING_AFTER,
    endingBefore,
    isEtfo,
  } = {
    locationIds: null,
    number: PAGE_NUMBER,
    size: PAGE_SIZE,
  },
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const query = {
    filter: areaIdsToFilters({ locationIds }),
    page: {
      size,
      starting_after: startingAfter,
      ending_before: endingBefore,
    },
  }

  if (name) {
    query.filter.name = {
      ilike: name,
    }
  }

  if (excludedManagerIds) {
    query.filter.manager_id = {
      nin: excludedManagerIds,
    }
  }

  if (display) {
    query.display = snakeCase(display)
  }

  if (isEtfo) {
    query.sort = 'name'
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/departments`,
      types,
      query: {
        ...query,
        include,
      },
    }),
  )
}

export const initDepartments = params => {
  return departmentsCall(INIT_DEPARTMENTS, params)
}

export const loadDepartments = params => {
  return departmentsCall(LOAD_DEPARTMENTS, params)
}

export const clearDepartments = () => ({
  type: CLEAR_DEPARTMENTS,
})

const jobsCall = (
  types,
  {
    include,
    locationIds = null,
    departmentIds = null,
    name = null,
    number = PAGE_NUMBER,
    size = PAGE_SIZE,
    display,
    startingAfter = STARTING_AFTER,
    endingBefore,
    isEtfo,
  } = {
    locationIds: null,
    departmentIds: null,
    number: PAGE_NUMBER,
    size: PAGE_SIZE,
  },
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const query = {
    filter: areaIdsToFilters({ locationIds, departmentIds }),
    page: {
      size,
      starting_after: startingAfter,
      ending_before: endingBefore,
    },
  }

  if (name) {
    query.filter.name = {
      ilike: name,
    }
  }

  if (display) {
    query.display = snakeCase(display)
  }

  if (isEtfo) {
    query.sort = 'name'
  }

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: `/companies/${companyId}/jobs/search`,
      types,
      query: {
        ...query,
        include,
      },
    }),
  )
}

export const initJobs = params => {
  return jobsCall(INIT_JOBS, params)
}

export const loadJobs = params => {
  return jobsCall(LOAD_JOBS, params)
}

export const clearJobs = () => ({
  type: CLEAR_JOBS,
})

const loadEmployeesInclude = [
  'profile',
  'jobsEmployees',
  'jobs.department',
  'jobs.branch',
  'branch',
  'supervisors.user.profile',
  'departments',
  'branches',
]

const employeesCall = (
  types,
  {
    accessLevels = null,
    locationIds = null,
    departmentIds = null,
    jobIds = null,
    employeeQuery = null,
    number = PAGE_NUMBER,
    size = PAGE_SIZE,
    startingAfter = STARTING_AFTER,
    endingBefore,
  } = {
    number: PAGE_NUMBER,
    size: PAGE_SIZE,
  },
) => (dispatch, getState) => {
  return dispatch(
    apiCall({
      endpoint: `/companies/${getCompanyId(getState())}/employees`,
      types,
      query: {
        include: loadEmployeesInclude.join(),
        filter: {
          ...(accessLevels ? { access_level: { in: accessLevels } } : {}),
          ...(employeeQuery ? { search: { ilike: employeeQuery } } : {}),
          ...areaIdsToFilters({ locationIds, departmentIds, jobIds }),
        },
        page: {
          size,
          starting_after: startingAfter,
          ending_before: endingBefore,
        },
      },
    }),
  )
}

export const initEmployees = params => {
  return employeesCall(INIT_EMPLOYEES, params)
}

export const loadEmployees = params => {
  return employeesCall(LOAD_EMPLOYEES, params)
}

export const clearEmployees = () => ({ type: CLEAR_EMPLOYEES })

const staffIncludes = [
  'employees.activeTimeEntry.branch',
  'employees.activeTimeEntry.department',
  'employees',
  'employees.branch',
  'employees.jobsEmployees.job',
  'employees.jobsEmployees.jobsTags',
  'manager',
  'manager.branches',
  'manager.all_managed_branches',
  'manager.all_managed_departments.branch',
  'manager.departments.branches',
  'profile.user',
  'profile.profileAvatar',
]

export const staffCall = (
  types,
  {
    sort,
    filters = {},
    number,
    size = PAGE_SIZE,
    display,
    startingAfter = STARTING_AFTER,
    endingBefore,
  } = {},
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  let displayPortion = null
  if (display) {
    displayPortion = { display }
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/staff`,
      query: {
        include: staffIncludes.join(),
        sort,
        ...displayPortion,
        filter: {
          ...areasToFilters(filters.areas || {}),
          ...accessLevelsToFilters(filters.accessLevels),
          ...statusesToFilters(filters.statuses),
          ...searchToFilters(filters.search),
          missing_fields: filters.missingFields,
        },
        page: {
          size,
          starting_after: startingAfter,
          ending_before: endingBefore,
        },
      },
      paged: true,
      types,
    }),
  )
}

export const initStaff = params => {
  return staffCall(INIT_STAFF, params)
}

export const loadStaff = params => {
  return staffCall(LOAD_STAFF, params)
}

export const clearStaff = () => ({ type: CLEAR_STAFF })

const loadJobsEmployeesInclude = [
  'employee.branch',
  'employee.profile',
  'job.department',
  'job.branch',
]

const jobsEmployeesCall = (
  types,
  {
    locationIds = null,
    departmentIds = null,
    jobIds = null,
    employeeQuery = null,
    orConditions = null,
    number = PAGE_NUMBER,
    size = PAGE_SIZE,
    startingAfter = STARTING_AFTER,
    endingBefore,
  } = {
    number: PAGE_NUMBER,
    size: PAGE_SIZE,
  },
) => (dispatch, getState) => {
  return dispatch(
    apiCall({
      endpoint: `/companies/${getCompanyId(getState())}/jobs_employees`,
      types,
      query: {
        include: loadJobsEmployeesInclude.join(),
        filter: {
          ...(employeeQuery
            ? { employee: { search: { ilike: employeeQuery } } }
            : {}),
          ...areaIdsToFilters({ locationIds, departmentIds, jobIds }),
          // Example of or_conditions: ['job_id', 'branch_id', 'department_id']
          ...(orConditions && { or_conditions: orConditions }),
        },
        page: {
          size,
          starting_after: startingAfter,
          ending_before: endingBefore,
        },
      },
    }),
  )
}

export const initJobsEmployees = params => {
  return jobsEmployeesCall(INIT_JOBS_EMPLOYEES, params)
}

export const loadJobsEmployees = params => {
  return jobsEmployeesCall(LOAD_JOBS_EMPLOYEES, params)
}

export const clearJobsEmployees = () => ({ type: CLEAR_JOBS_EMPLOYEES })

export const clear = () => ({ type: CLEAR })
