import { useModifyShiftJobAssignments } from './useModifyShiftJobAssignments'
import { useShiftJobsByCursor } from './useShiftJobsByCursor'
import { useShiftJobsByCursorWithSchedules } from './useShiftJobsByCursorWithSchedules'

export * from './types'

export const ShiftJobsAPI = {
  byShiftIdByCursor: useShiftJobsByCursor,
  byShiftIdByCursorWithSchedules: useShiftJobsByCursorWithSchedules,
  modifyAssignments: useModifyShiftJobAssignments,
}
