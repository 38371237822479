import differenceBy from 'lodash/differenceBy'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'

import { classifierToOption } from 'services/Options'

import {
  ClassifierGroupedOption,
  ClassifierOption,
  ClassifierOptionType,
} from './types'

export function filterClassifiers(
  classifiers: Gateway.Classifier[],
  excludeClassifiers: ClassifierOption[],
) {
  let entities = classifiers ? [...classifiers] : []

  if (!isEmpty(excludeClassifiers) && entities) {
    const values = excludeClassifiers.map(({ id }) => ({ id }))
    entities = differenceBy(entities, values, 'id')
  }

  return entities
}

export function groupClassifiers(classifierOptions: ClassifierOptionType[]) {
  const groupedClassifierOptions = groupBy(
    classifierOptions,
    'classifier.fieldName',
  )
  const options = Object.entries(groupedClassifierOptions)
    .map(([key, value]) => ({
      label: key,
      options: value,
    }))
    .sort()

  return options
}

export function sortClassifierOptions(classifiers: ClassifierGroupedOption[]) {
  return classifiers.sort((a, b) => {
    const nameA = a.label.toUpperCase()
    const nameB = b.label.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    return 0
  })
}

export function generateClassifierDefaultOptions(
  classifiers: Gateway.Classifier[],
  excludeClassifiers: ClassifierOption[],
) {
  const filteredClassifiers = filterClassifiers(classifiers, excludeClassifiers)
  const classifierOptions = filteredClassifiers.map(classifierToOption)
  const groupedClassifierOptions = groupClassifiers(classifierOptions)
  const sortedClassifierOptions = sortClassifierOptions(
    groupedClassifierOptions,
  )

  return sortedClassifierOptions
}
