import React from 'react'

import { ConflictType } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

type Props = {
  conflictType: ConflictType
}

// TODO: add common i18n for this component & RuleRenderer->ShiftHoliday
export function ShiftHoliday({ conflictType }: Props) {
  return (
    <>
      If employees are assigned to shifts on a public holiday, invoke a{' '}
      <b>{Utils.Conflict.getConflictTypeText(conflictType)}.</b>
    </>
  )
}
