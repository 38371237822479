import styled, { css } from 'styled-components'

import { inputCss } from 'components/ui/__v3__/Input/styles'

import { TimeEntryState } from 'constants/gatewayGraphQL'

export const Layout = styled.div`
  display: grid;
  align-items: end;
  grid-template-columns: 180px 120px 120px 60px 160px auto;
  grid-gap: ${({ theme }) => theme.space[1]}px;
`

export const DurationValue = styled.div`
  ${inputCss}

  background-color: ${({ theme }) => theme.colors.HAWKES_BLUE};
  border-color: ${({ theme }) => theme.colors.DODGER_BLUE};
  color: ${({ theme }) => theme.colors.BLACK};
  font-weight: 500;
  line-height: 16px;
  cursor: default;
`

export const HelperRow = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 12px;
  margin-top: ${({ theme }) => theme.space[1]}px;
  gap: ${({ theme }) => theme.space[2]}px;
`

export const HelperAction = styled.span`
  color: ${({ theme }) => theme.layout.primary};
  cursor: pointer;
`

type Props = {
  state: TimeEntryState
}

const colorCss = ({ state }: Props) => {
  if (state === TimeEntryState.Approved) {
    return css`
      color: ${({ theme }) => theme.toasts.secondaryColor.success};
    `
  }

  if (state === TimeEntryState.Discarded) {
    return css`
      color: ${({ theme }) => theme.toasts.secondaryColor.error};
    `
  }

  if (state === TimeEntryState.Pending || state === TimeEntryState.Future) {
    return css`
      color: ${({ theme }) => theme.toasts.secondaryColor.info};
    `
  }

  return css`
    color: inherit;
  `
}

export const TimeEntryStatus = styled.span<Props>`
  ${colorCss}
`

export const HelperStatus = styled.div`
  display: flex;
  color: ${({ theme }) => theme.input.errors.icon.color};
  gap: ${({ theme }) => theme.space[2]}px;
`
