import React, { useMemo } from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { Span } from 'components/ui/__v2__/Grid'

import Utils from 'services/Utils'

type Props = {
  rule: ShiftValidationRuleAPIItem
}

export function AdditionalLimitationsAndSettings({ rule }: Props) {
  const { hasGroups, groupsString } = useMemo(() => {
    const hasGroupCondition =
      !!rule.employeeFilter.groups?.length &&
      !!rule.employeeFilter.groupCondition

    if (!hasGroupCondition) return { hasGroups: false, groupsString: [] }

    const joinedGroupNames = rule.employeeFilter.groups
      ?.map(group => group.name)
      .join(', ')

    return { hasGroups: hasGroupCondition, groupsString: joinedGroupNames }
  }, [rule])

  if (!hasGroups) return null

  return (
    <Span>
      <i>Additional limitations and settings:</i> Limit rule application to
      employees that are members of{' '}
      <b>
        {Utils.Rule.getGroupConditionText(rule.employeeFilter.groupCondition)}
      </b>{' '}
      following groups: <b>{groupsString}.</b>{' '}
    </Span>
  )
}
