import {
  DocumentNode,
  LazyQueryHookOptions,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  TypedDocumentNode,
  useLazyQuery,
  useQuery,
} from '@apollo/client'

import { useLocale } from 'hooks/Locale'
import { useAppContext } from 'hooks/useAppContext'

import { i18n, TranslationKeys } from 'i18n'

import { showToast, ToastMessage } from 'services/Toasts'

export interface UseQueryOptions {
  translationKey?: TranslationKeys
  silent?: boolean
}

export type { QueryHookOptions }

export function useApolloQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & UseQueryOptions,
): QueryResult<TData, TVariables> {
  const { company } = useAppContext()
  const locale = useLocale()

  const innerOptions = {
    ...options,
    notifyOnNetworkStatusChange: true,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
        'company-id': company.microserviceId,
        'core-company-id': company.coreId,
        'accept-language': locale,
      },
    },
  }

  if (options?.silent) {
    return useQuery(query, innerOptions)
  }

  const message = getErrorMessage(options)
  const onError = getOnError(message)

  return useQuery(query, {
    ...innerOptions,
    onError,
  })
}

export function useApolloLazyQuery<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables> & UseQueryOptions,
): QueryTuple<TData, TVariables> {
  const { company } = useAppContext()
  const locale = useLocale()

  const innerOptions = {
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
        'company-id': company.microserviceId,
        'core-company-id': company.coreId,
        'accept-language': locale,
      },
    },
  }

  if (options?.silent) {
    return useLazyQuery(query, innerOptions)
  }

  const message = getErrorMessage(options)
  const onError = getOnError(message)

  return useLazyQuery(query, {
    ...innerOptions,
    onError,
  })
}

function getOnError(message: ToastMessage) {
  return () =>
    showToast({
      type: 'error',
      title: message.title,
      content: message.content,
    })
}

function getErrorMessage(options?: UseQueryOptions): ToastMessage {
  if (options?.translationKey) {
    return {
      title: i18n(`${options.translationKey}.title` as TranslationKeys),
      content: i18n(`${options.translationKey}.message` as TranslationKeys),
    }
  }

  return {
    title: i18n('alertMessages.default.error.title'),
    content: i18n('alertMessages.default.error.message'),
  }
}
