import React from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { Divider } from 'components/ui/__v2__/Divider'
import { SpacedColumn } from 'components/ui/__v2__/Grid'
import { Button } from 'components/ui/__v3__'
import {
  FormCheckbox,
  FormDayRangePicker,
  FormMaskedNumeric,
} from 'components/ui/__v3__/HookForm'
import { Modal } from 'components/ui/__v3__/Modal'

import { POSITIVE_NUMBER_MASK } from 'constants/settings'

import { useI18n } from 'hooks'

import i18n from 'i18n'

import { defaultValues, validationSchema } from './formConfig'
import { MINUTES_MASK } from './helpers'
import { IconText } from './styles'
import { AutoAssignConfigFormState, AutoAssignConfigProps } from './types'

const FORM_VALUE_INTEGER_LIMIT = 3

export function AutoAssignConfig({
  open,
  onClose,
  onStart,
}: AutoAssignConfigProps) {
  const t = useI18n('autoAssign')

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<AutoAssignConfigFormState>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues,
  })

  function startAutoAssignTask(params: AutoAssignConfigFormState) {
    onStart(params)
    onClose()
  }

  return (
    <Modal.Container isOpen={open}>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{t('title')}</Modal.Title>

      <form onSubmit={handleSubmit(startAutoAssignTask)}>
        <SpacedColumn my={5} space={2}>
          <FormDayRangePicker<AutoAssignConfigFormState>
            control={control}
            disablePast
            labelText={t('configurations.dateRange')}
            name="dateRange"
            required
          />

          <FormMaskedNumeric
            control={control}
            icon={<IconText>{t('configurations.solveDuration.icon')}</IconText>}
            labelText={t('configurations.solveDuration.label')}
            mask={MINUTES_MASK}
            name="solveDuration"
            required
            width="100px"
          />

          <Divider borderWidth={1} mt={20} />

          <FormMaskedNumeric<AutoAssignConfigFormState>
            control={control}
            labelText={t('configurations.minRestTime')}
            mask={POSITIVE_NUMBER_MASK(FORM_VALUE_INTEGER_LIMIT)}
            name="minRestTime"
          />

          <FormMaskedNumeric<AutoAssignConfigFormState>
            control={control}
            labelText={t('configurations.minOvernightRestTime')}
            mask={POSITIVE_NUMBER_MASK(FORM_VALUE_INTEGER_LIMIT)}
            name="minOvernightRestTime"
          />

          <FormMaskedNumeric<AutoAssignConfigFormState>
            control={control}
            labelText={t('configurations.dailyShiftLimit')}
            mask={POSITIVE_NUMBER_MASK(FORM_VALUE_INTEGER_LIMIT)}
            name="dailyShiftLimit"
            width="100%"
          />

          <FormCheckbox<AutoAssignConfigFormState>
            control={control}
            labelText={t('configurations.unavailabilityAsHardConstraint')}
            name="unavailabilityAsHardConstraint"
          />
        </SpacedColumn>

        <Modal.Actions justifyContent="center">
          <Button mr={17} secondary width={180} onClick={onClose}>
            {i18n('actions.cancel')}
          </Button>

          <Button disabled={!isValid || isSubmitting} width={180}>
            {i18n('actions.confirm')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal.Container>
  )
}
