import { useApolloMutation } from 'API/services/Apollo'

import { CreateClassifierMutation } from './GraphQL'

export function useCreateClassifier() {
  const [innerCreate, { loading }] = useApolloMutation<
    MutationData<'createClassifier'>,
    Gateway.MutationCreateClassifierArgs
  >(CreateClassifierMutation, {
    entity: 'settings.classifiers.title',
    mutationType: 'create',
    update: cache => cache.evict({ fieldName: 'classifiersByCursor' }),
  })

  async function createClassifier(
    input: Omit<Gateway.CreateClassifierInput, 'companyId'>,
  ) {
    const result = await innerCreate({
      variables: {
        input,
      },
    })
    return Boolean(result.errors)
  }

  return {
    createClassifier,
    creatingClassifier: loading,
  }
}
