import { DateTime, Interval } from 'luxon'
import { RRule } from 'rrule'

import fill from 'lodash/fill'
import has from 'lodash/has'
import map from 'lodash/map'
import memoize from 'lodash/memoize'
import replace from 'lodash/replace'
import upperFirst from 'lodash/upperFirst'

export const convertMinutesToHM = memoize(mins => {
  let h = Math.floor(mins / 60)
  let m = Math.floor(mins % 60)
  h = h < 10 ? `0${h}` : h
  m = m < 10 ? `0${m}` : m
  return `${h}:${m}`
})

export function convertSecondsToHM(seconds) {
  const mins = seconds / 60
  return convertMinutesToHM(mins)
}

/**
 * Converts `HH:MM` to minutes
 * @see https://stackoverflow.com/a/32885749
 *
 * @param {string} hm `HH:MM` string
 * @returns Minutes integer
 */
export function convertHMToMinutes(hm) {
  const arr = hm.split(':')
  return +arr[0] * 60 + +arr[1]
}

export function convertHMToSeconds(hm) {
  const minutes = convertHMToMinutes(hm)
  return minutes * 60
}

export const currentWeekStartISO = DateTime.local().startOf('week').toISO()
export const currentWeekEndISO = DateTime.local().endOf('week').toISO()

export const convertSecondsToHMS = memoize(timeInSeconds => {
  let hours = Math.floor(timeInSeconds / 3600)
  let minutes = Math.floor((timeInSeconds - hours * 3600) / 60)
  let seconds = timeInSeconds - hours * 3600 - minutes * 60

  hours = hours < 10 ? `0${hours}` : hours
  minutes = minutes < 10 ? `0${minutes}` : minutes
  seconds = seconds < 10 ? `0${seconds}` : seconds

  return `${hours}:${minutes}:${seconds}`
})

/** @deprecated */
export const currentWeek = {
  start: currentWeekStartISO,
  end: currentWeekEndISO,
  day: DateTime.local().startOf('week').toISO(),
}

/** @deprecated */
export const getCurrentWeek = (workStartWeekDay = 1) => {
  const todayDate = DateTime.local()
  const todayWeekDay = todayDate.weekday
  const isCurrentWeek = todayWeekDay - workStartWeekDay >= 0

  if (!isCurrentWeek) {
    const previousWeek = todayDate.minus({
      week: 1,
    })

    return {
      start: previousWeek
        .startOf('day')
        .set({ weekday: workStartWeekDay })
        .toISO(),
      end: previousWeek
        .endOf('day')
        .set({ weekDay: workStartWeekDay })
        .plus({
          days: 6,
        })
        .toISO(),
      day: previousWeek.set({ weekDay: workStartWeekDay }).toISO(),
    }
  }

  return {
    start: todayDate.startOf('day').set({ weekday: workStartWeekDay }).toISO({
      includeOffset: false,
    }),
    end: todayDate
      .endOf('day')
      .set({ weekday: workStartWeekDay })
      .plus({
        days: 6,
      })
      .toISO(),
    day: todayDate.set({ weekday: workStartWeekDay }).toISO(),
  }
}

export const getWeekFromDate = date => ({
  start: DateTime.fromISO(date).startOf('week').toISO(),
  end: DateTime.fromISO(date).endOf('week').toISO(),
})

export function stringifyDateRange({ start, end }) {
  return {
    startOn: DateTime.fromISO(start).toISODate(),
    finishOn: DateTime.fromISO(end).toISODate(),
  }
}

export const weekDays = week => {
  const localWeek = week || {
    start: currentWeekStartISO,
    end: currentWeekEndISO,
  }

  const startDate = DateTime.fromISO(localWeek.start)
  const endDate = DateTime.fromISO(localWeek.end)
  const diff = endDate.diff(startDate, ['days'])
  const length = Math.max(Math.ceil(diff.days), 1)

  return fill(new Array(length), '').map((empty, index) =>
    startDate.plus({ days: index }).toISO(),
  )
}

export const getWeekDaysArray = week => {
  const diff = week.end.diff(week.start.minus({ days: 1 }), ['days'])

  const length = Math.max(Math.floor(diff.days), 1)

  return fill(new Array(length), '').map((__, index) =>
    week.start.plus({ days: index }),
  )
}

export const addWeek = memoize(week => ({
  start: DateTime.fromISO(week.start).plus({ weeks: 1 }).toISO(),
  end: DateTime.fromISO(week.end).plus({ weeks: 1 }).toISO(),
  day: DateTime.fromISO(week.day).plus({ weeks: 1 }).toISO(),
}))

export const subWeek = memoize(week => ({
  start: DateTime.fromISO(week.start).minus({ weeks: 1 }).toISO(),
  end: DateTime.fromISO(week.end).minus({ weeks: 1 }).toISO(),
  day: DateTime.fromISO(week.day).minus({ weeks: 1 }).toISO(),
}))

export const formatDate = memoize((dateString, locale = 'en') =>
  DateTime.fromISO(dateString).setLocale(locale).toFormat("MMMM dd', 'kkkk"),
)

export const TIME_FORMAT_SSSZZ = "yyyy'-'MM'-'dd'T'TT'.'SSSZZ"

export const parseTime = time => {
  const [hours, minutes] = time.split(':').map(parseFloat)
  return DateTime.local().set({ hour: hours, minute: minutes }).toISO()
}

export function toISOString(date = new Date()) {
  return new Date(date.toUTCString()).toISOString()
}

export function calculateTimeIntervalInMinutes(startAt, finishAt) {
  return (
    // The subtraction returns the difference between the two dates in milliseconds.
    // 6e4 is the scientific notation for 60000,
    // dividing by which converts the milliseconds difference into minutes.
    Math.abs(new Date(finishAt) - new Date(startAt)) / 6e4
  )
}

export function getDayIndex(date) {
  if (date) {
    return DateTime.fromISO(date).weekday - 1
  }
  return null
}

export function replaceAmPmToLower(time) {
  return (
    time &&
    typeof time === 'string' &&
    time.replace('AM', 'a.m.').replace('PM', 'p.m')
  )
}

export function formatISOToMonthDayYear(timeISO) {
  if (!timeISO) {
    return ''
  }

  return DateTime.fromISO(timeISO).toLocaleString(DateTime.DATE_MED)
}

export function formatISOToDayMonth(timeISO) {
  if (!timeISO) {
    return ''
  }

  return DateTime.fromISO(timeISO).toFormat('LLL dd')
}

export function formatISOToMonthDayYearHours(timeISO) {
  if (!timeISO) {
    return ''
  }

  return DateTime.fromISO(timeISO).toLocaleString(DateTime.DATE_HUGE)
}

export function formatISOToSimpleTime(timeISO) {
  if (!timeISO) {
    return ''
  }

  return DateTime.fromISO(timeISO).toLocaleString(DateTime.TIME_SIMPLE)
}

export function isSameDay(date1, date2, unit = 'day', timezone = 'local') {
  let comparableDate1
  let comparableDate2

  if (date1 instanceof Date) {
    comparableDate1 = DateTime.fromJSDate(date1)
  }
  if (date2 instanceof Date) {
    comparableDate2 = DateTime.fromJSDate(date2)
  }

  if (date1 instanceof DateTime) {
    comparableDate1 = date1
  }
  if (date2 instanceof DateTime) {
    comparableDate2 = date2
  }

  if (typeof date1 === 'string') {
    comparableDate1 = DateTime.fromISO(date1)
  }
  if (typeof date2 === 'string') {
    comparableDate2 = DateTime.fromISO(date2, { zone: timezone })
  }

  if (comparableDate1 == null || comparableDate2 == null) {
    return false
  }

  return comparableDate1.hasSame(comparableDate2, unit)
}

/** @deprecated */
export function formatISOToYearMonthDayHoursMillis(dateISO) {
  return DateTime.fromISO(dateISO)
    .setZone('utc')
    .toFormat("yyyy'-'MM'-'dd HH':'mm':'ss")
}

// Compare the two dates and return 1 if the first date is after the second, -1
// if the first date is before the second or 0 if dates are equal.
export function compareAsc(date1, date2) {
  const d1 = DateTime.fromISO(date1)
  const d2 = DateTime.fromISO(date2)
  const diff = d1.diff(d2).toObject().milliseconds
  if (diff > 0) return 1
  if (diff < 0) return -1
  return 0
}

/**
 * check if targetRange inclusively contains currentRange
 *
 * @param {Object} targetRange - the target range which includes start and end attributes
 * @param {string} targetRange.start - the start point of the target range
 * @param {string} targetRange.end - the end point of the target range
 * @param {Object} currentRange - the current range to be tested containing by targetRange or not
 * @param {string} currentRange.from - the start point of the current range
 * @param {string} currentRange.to - the end point of the current range
 * @returns {boolean}
 */
export function contain(targetRange, currentRange) {
  const interval = Interval.fromDateTimes(
    DateTime.fromISO(targetRange?.start),
    DateTime.fromISO(targetRange?.end).endOf('day'),
  )

  const from = DateTime.fromISO(currentRange?.from)
  const to = DateTime.fromISO(currentRange?.to)

  return interval.contains(from) && interval.contains(to)
}

export function translateRelativeInFrench(phrase) {
  const translations = {
    'last lundi': 'Lundi dernier',
    'last mardi': 'Mardi dernier',
    'last mercredi': 'Mercredi dernier',
    'last jeudi': 'Jeudi dernier',
    'last vendredi': 'Vendredi dernier',
    'last samedi': 'Samedi dernier',
    'last dimanche': 'Dimanche dernier',
    // 'Last week': 'la semaine dernière',
    // 'Last month': 'le mois dernier',
    // '2 weeks ago': 'il y a 2 semaines',
    // '2 months ago': 'il y a 2 mois',
    // '5 days ago': 'il y a 5 jours',
    // '30 days ago': 'il y a 30 jours',
    yesterday: 'hier',
    today: 'aujourd’hui',
  }

  for (const key in translations) {
    if (Object.prototype.hasOwnProperty.call(translations, key)) {
      phrase = replace(phrase, key, translations[key])
    }
  }

  return phrase
}

// TODO: Do I have to say this is straight bullshit??????
export function getHoursFormatByLocale(locale = 'en') {
  if (locale === 'fr') {
    return 'HH:mm'
  }
  return 'hh:mm a'
}

export function capitalizeFormattedTime(time, splitSymbol = ' ') {
  if (typeof time !== 'string') {
    return time
  }
  const parts = time.split(splitSymbol)
  return map(parts, part => upperFirst(part)).join(splitSymbol)
}

export function isFutureDate(dateISO) {
  const futureDate = DateTime.fromISO(dateISO)
  return futureDate.diffNow('days').days > 0
}

export function getCurrentMonth() {
  const today = DateTime.local()

  return {
    start: today.startOf('month'),
    end: today.endOf('month'),
  }
}

export function timeToDecimal(t) {
  const arr = t.split(':')
  const dec = parseInt((arr[1] / 6) * 10, 10)

  return parseFloat(`${parseInt(arr[0], 10)}.${dec < 10 ? '0' : ''}${dec}`)
}

export function getIsPm(date) {
  return !!date.toLocaleString(DateTime.TIME_SIMPLE).match(/pm/i)
}

export function localeWithMeridiem() {
  return !!DateTime.local().toLocaleString(DateTime.TIME_SIMPLE).match(/am|pm/i)
}

export function isDisabledPastTime(currentDate) {
  if (!currentDate) {
    return false
  }

  const dateOrdinal = DateTime.fromJSDate(currentDate).ordinal
  const localOrdinal = DateTime.local().ordinal

  return dateOrdinal === localOrdinal
}

/**
 * Convert ISO date to JS date with Luxon
 *
 * @param {string | number} date ISO date string
 * @return {Date | null} JS date or null
 */
export function isoToJsDate(
  date,
  zone = null,
  { pipe } = { pipe: value => value },
) {
  return date ? pipe(DateTime.fromISO(date, { zone })).toJSDate() : null
}

export function getStartOfWeek({
  workweekStartDay = 1,
  timezone = 'local',
} = {}) {
  const today = DateTime.local()
  let startOfWeek = today.startOf('week').plus({ days: workweekStartDay - 1 })

  if (today < startOfWeek) {
    startOfWeek = startOfWeek.minus({ weeks: 1 })
  }

  return startOfWeek.setZone(timezone, {
    keepLocalTime: true,
  })
}

/**
 * Get diff in seconds and ignore DST
 *
 * @param {DateTime} from
 * @param {DateTime} to
 */
export function getSecondsDiff(from, to) {
  let diff = from.diff(to).as('seconds')

  // Add hour at DST start
  if (!to.isInDST && from.isInDST) {
    diff += 3600
  }

  // Subtract hour at DST end
  if (to.isInDST && !from.isInDST) {
    diff -= 3600
  }

  return diff
}

export function isoToLocal(isoDateTime, opts = null) {
  return DateTime.fromISO(isoDateTime).toLocaleString(opts)
}

export function isoToLocalShort(isoDateTime) {
  return isoToLocal(isoDateTime, { ...DateTime.DATETIME_SHORT })
}

export function isoToLocaleRange(fromIso, toIso) {
  /**
   * Some basic range formatting
   */
  if (!fromIso) return null

  if (fromIso === toIso) return isoToLocal(fromIso)
  return `${isoToLocal(fromIso)} - ${isoToLocal(toIso)}`
}

export function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getBrowserLocale() {
  return Intl.DateTimeFormat().resolvedOptions().locale
}

function* getDays(interval) {
  let cursor = interval.start.startOf('day')
  while (cursor <= interval.end) {
    yield cursor
    cursor = cursor.plus({ days: 1 })
  }
}

function rangeToInterval(range) {
  if (!has(range, 'from') || !has(range, 'to')) return null
  const { from, to } = range
  const start = DateTime.fromISO(from)
  const end = DateTime.fromISO(to)

  const interval = Interval.fromDateTimes(start, end)

  return interval
}

export function rangeToDatetimeArray(range) {
  return Array.from(getDays(rangeToInterval(range)))
}

/**
 * Calculate number of days for given start and end ISO dates
 *
 * @param {string } from ISO date string
 * @param {string } to ISO date string
 * @return {number | null } Number of days
 */
export function getNumberOfDays({ from, to }, except) {
  const startDate = DateTime.fromISO(from)
  const endDate = DateTime.fromISO(to)

  if (startDate.isValid && endDate.isValid) {
    return (
      Interval.fromDateTimes(startDate, endDate).count('days') - except?.length
    )
  }

  return null
}

export function selectedDaysToRRule(selectedDateTimes) {
  return map(selectedDateTimes, day => {
    return [
      RRule.MO,
      RRule.TU,
      RRule.WE,
      RRule.TH,
      RRule.FR,
      RRule.SA,
      RRule.SU,
    ][day.weekday - 1]
  })
}
