import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  ArchiveTimeBucketMutation,
  TimeBucketChildrenByCursorQuery,
  TimeBucketParentsByCursorQuery,
} from './GraphQL'

export function useArchiveTimeBucket() {
  const [archive, { loading: archiving }] = useApolloMutation<
    MutationData<'archiveTimeBucket'>,
    Gateway.MutationArchiveTimeBucketArgs
  >(ArchiveTimeBucketMutation, {
    // TODO: split messages for parent and child
    translationKey: 'settings.timeBuckets.toasts.archive',
  })
  const archiveTimeBucket = async (
    id: Gateway.MutationArchiveTimeBucketArgs['id'],
    child?: boolean,
  ) => {
    return archive({
      variables: {
        id,
      },
      refetchQueries: compact([
        getOperationName(
          child
            ? TimeBucketChildrenByCursorQuery
            : TimeBucketParentsByCursorQuery,
        ),
      ]),
    })
  }

  return { archiveTimeBucket, archiving }
}
