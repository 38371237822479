import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { Select } from 'components/ui/__v3__/Select'

import { createDebounce } from 'helpers/debounce'
import { denormalize } from 'helpers/redux'

import _ from 'i18n'

import { showError } from 'services/API'
import { employeeToOption } from 'services/Options'

function EmployeesPicker({
  cacheOptions,
  initial,
  isDisabled,
  loadingText,
  placeholder,
  preload,
  showJobs,
  value,
  onClear,
  onLoad,
  onInit,
  isMulti,
  ...rest
}) {
  const defaultOptions = useMemo(
    () => map(initial.entities || [], employeeToOption),
    [initial.entities],
  )

  const locationIds = useMemo(() => {
    if (isEmpty(rest.locationIds)) return null

    return isArray(rest.locationIds) ? rest.locationIds : [rest.locationIds]
  }, [rest.locationIds])

  const departmentIds = useMemo(() => {
    if (isEmpty(rest.departmentIds)) return null

    return isArray(rest.departmentIds)
      ? rest.departmentIds
      : [rest.departmentIds]
  }, [rest.departmentIds])

  const jobIds = useMemo(() => {
    if (isEmpty(rest.jobIds)) return null

    return isArray(rest.jobIds) ? rest.jobIds : [rest.jobIds]
  }, [rest.jobIds])

  const accessLevels = useMemo(() => {
    if (isEmpty(rest.accessLevels)) return null

    return isArray(rest.accessLevels) ? rest.accessLevels : [rest.accessLevels]
  }, [rest.accessLevels])

  const initOptions = useCallback(() => {
    onInit({
      locationIds,
      departmentIds,
      jobIds,
      accessLevels,
    })
  }, [accessLevels, departmentIds, jobIds, locationIds, onInit])

  const prevLocationIds = useRef(locationIds)
  const prevDepartmentIds = useRef(departmentIds)
  const prevJobIds = useRef(jobIds)
  const prevAccessLevels = useRef(accessLevels)
  useEffect(() => {
    if (
      !isEqual(prevLocationIds.current, locationIds) ||
      !isEqual(prevDepartmentIds.current, departmentIds) ||
      !isEqual(prevJobIds.current, jobIds) ||
      !isEqual(prevAccessLevels.current, accessLevels)
    ) {
      onClear()
      prevLocationIds.current = locationIds
      prevDepartmentIds.current = departmentIds
      prevJobIds.current = jobIds
      prevAccessLevels.current = accessLevels
    }
  }, [locationIds, departmentIds, onClear, jobIds, accessLevels])

  useEffect(() => {
    if (!isDisabled && preload && !initial.isLoaded) {
      initOptions()
    }
  }, [initial.isLoaded, preload, isDisabled, initOptions])

  const debouncedLoad = useMemo(() => {
    return createDebounce(
      (inputValue, callback) => {
        if (!inputValue) {
          callback([])
        } else {
          onLoad({
            accessLevels,
            departmentIds,
            employeeQuery: inputValue,
            jobIds,
            locationIds,
          }).then(result => {
            if (result?.ok) {
              const data = get(result, ['payload', 'data'])
              const employeeIds = map(get(data, 'employees'), 'id')

              const denormalizedEmployees = isEmpty(employeeIds)
                ? []
                : denormalize(data, 'employees', employeeIds)

              callback(map(denormalizedEmployees, employeeToOption))
            } else {
              showError(result)
              callback([])
            }
          })
        }
      },
      { leading: true, trailing: true },
    )
  }, [onLoad, accessLevels, departmentIds, jobIds, locationIds])

  const renderLoadingMessage = useCallback(
    () => loadingText ?? `${_('common.loading')}...`,
    [loadingText],
  )

  return (
    <Select
      async
      cacheOptions={cacheOptions}
      closeMenuOnSelect={!isMulti}
      defaultOptions={defaultOptions}
      isClearable={false}
      isDisabled={isDisabled}
      isLoading={initial.isLoading}
      isMulti={isMulti}
      isSearchable
      loadOptions={debouncedLoad}
      loadingMessage={renderLoadingMessage}
      placeholder={placeholder}
      value={value}
      onMenuOpen={initOptions}
      {...rest}
    />
  )
}

EmployeesPicker.defaultProps = {
  accessLevels: null,
  cacheOptions: true,
  departmentIds: null,
  initial: {},
  isDisabled: false,
  jobIds: null,
  loadingText: null,
  locationIds: null,
  placeholder: null,
  preload: false,
  showJobs: false,
  value: null,
  withPortal: true,
  onClear: noop,
  onInit: noop,
  onLoad: noop,
}

EmployeesPicker.propTypes = {
  ...Select.propTypes,
  accessLevels: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  cacheOptions: PropTypes.bool,
  departmentIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  initial: PropTypes.object,
  isDisabled: PropTypes.bool,
  jobIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  loadingText: PropTypes.string,
  locationIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  placeholder: PropTypes.string,
  preload: PropTypes.bool,
  showJobs: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  withPortal: PropTypes.bool,
  onClear: PropTypes.func,
  onInit: PropTypes.func,
  onLoad: PropTypes.func,
}

export default EmployeesPicker
