import React, { useMemo } from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { ConflictType, RuleType } from 'constants/gatewayGraphQL'

import {
  DayOfWeek,
  EmployeeAssignmentThreshold,
  EmployeeDaysOffWeekThreshold,
  ShiftHoliday,
  ShiftRestTime,
  SingleShiftLengthThreshold,
} from './components'

type Props = {
  conflictType: ConflictType
  rule: ShiftValidationRuleAPIItem
}

export function ShiftValidationConflictRuleConditions({
  rule,
  conflictType,
}: Props) {
  const ruleContent = useMemo(() => {
    const props = { conflictType, rule }

    switch (rule.ruleType) {
      case RuleType.EmployeeAssignmentThreshold:
        return <EmployeeAssignmentThreshold {...props} />

      case RuleType.EmployeeDaysOffThreshold:
        return <EmployeeDaysOffWeekThreshold {...props} />

      case RuleType.ShiftLengthThreshold:
        return <SingleShiftLengthThreshold {...props} />

      case RuleType.ShiftRestTime:
        return <ShiftRestTime {...props} />

      case RuleType.DayOfWeek:
        return <DayOfWeek {...props} />

      case RuleType.ShiftHoliday:
        return <ShiftHoliday conflictType={conflictType} />
      default:
        return null
    }
  }, [rule, conflictType])

  return <>{ruleContent}</>
}
