import { useApolloMutation } from 'API/services/Apollo'

import { UpdateRulesetMutation } from './GraphQL'

export function useUpdateRuleset() {
  const [innerUpdate, { loading: updating }] = useApolloMutation<
    MutationData<'updateRuleset'>,
    Gateway.MutationUpdateRulesetArgs
  >(UpdateRulesetMutation, {
    update: cache => {
      cache.evict({ fieldName: 'ruleset' })
      cache.evict({ fieldName: 'rulesByCursor' })

      cache.gc()
    },
  })

  const updateRuleset = async (input: Gateway.UpdateRulesetInput) =>
    innerUpdate({
      variables: {
        input,
      },
    })

  return { updateRuleset, updating }
}
