import { useCallback } from 'react'

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CreateUpdateTimeEntriesMutation,
  TimeEntriesByCursorQuery,
  TimeEntrySummariesByCursorQuery,
} from './GraphQL'

export function useCreateUpdateTimeEntries() {
  const [innerCreateUpdate, { loading, error }] = useApolloMutation<
    null,
    {
      createInput: Gateway.CreateTimeEntriesInput
      updateInput: Gateway.UpdateTimeEntriesInput
    }
  >(CreateUpdateTimeEntriesMutation, {
    refetchQueries: compact(
      [TimeEntriesByCursorQuery, TimeEntrySummariesByCursorQuery].map(
        getOperationName,
      ),
    ),
  })

  const createUpdate = useCallback(
    async ({ createInput, updateInput }) => {
      const result = await innerCreateUpdate({
        variables: {
          createInput,
          updateInput,
        },
      })

      return graphQLResultHasError(result)
    },
    [innerCreateUpdate],
  )

  return { createUpdate, upserting: loading, createError: error }
}
