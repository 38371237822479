import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'

export const SelectAll = styled(Flex)`
  font-size: 12px;
  height: 30px;
  padding-left: 5px;
  ${props => {
    return props.disabled
      ? css`
          pointer-events: none;
          color: ${themeGet('layout.disabled')};
        `
      : css`
          cursor: pointer;
          color: ${themeGet('layout.primary')};
        `
  }}
`
