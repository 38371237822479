import { useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { ClassifiersByCursorQuery } from './GraphQL'
import { ClassifierFilter } from './types'

export function useClassifiersByCursor({
  page,
  filter,
  sort,
}: {
  page: CursorPage
  filter: ClassifierFilter
  sort: Gateway.ClassifierSorting
}) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, loading } = useApolloQuery<
    QueryData<'classifiersByCursor'>,
    Gateway.QueryClassifiersByCursorArgs
  >(ClassifiersByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      sorting: sort,
      filter,
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data.classifiersByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const classifiers =
    data?.classifiersByCursor?.edges?.map(({ node }) => node) ?? []

  return {
    classifiers,
    classifiersLoading: loading,
    classifiersRefetch: refetch,
    classifiersPageMeta: currentPageMeta,
  }
}
