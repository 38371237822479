import { useQuery } from '@tanstack/react-query'
import { CompanyHolidayAPIItem } from 'API/Company/types'
import { STALE_TIME } from 'API/services/JsonApi'
import { transformResponseToEntities } from 'API/services/utils'

import {
  COMPANY_HOLIDAYS_JSON_API_TYPE,
  CompanyHolidaysQueryKey,
} from './constants'
import { companyHolidaysQuery, CompanyHolidaysQueryArgs } from './Json'

// https://tanstack.com/query/v4/docs/react/reference/useQuery

export function useCompanyHolidays(
  companyId: string,
  filter: CompanyHolidaysQueryArgs['filter'],
) {
  const { data, isFetching, error } = useQuery({
    structuralSharing: false,
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME.VERY_SLOW,

    queryKey: CompanyHolidaysQueryKey.byId({
      id: companyId,
    }),
    queryFn: () => companyHolidaysQuery({ companyId, filter }),

    select: response => {
      const { entities } = transformResponseToEntities<CompanyHolidayAPIItem>({
        response,
        jsonApiType: COMPANY_HOLIDAYS_JSON_API_TYPE,
      })

      return entities
    },
  })

  return {
    companyHolidays: data,
    companyHolidaysLoading: isFetching,
    companyHolidayError: error,
  }
}
