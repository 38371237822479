import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { Span } from 'components/ui/__v2__/Grid'

import { RuleParametersComparison } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

type Props = {
  rule: ShiftValidationRuleAPIItem
  withSemicolon?: boolean
  formSubPath:
    | 'shiftLengthThreshold'
    | 'shiftRestTime'
    | 'employeeAssignmentThreshold'
}

export function HoursRangeCondition({
  rule,
  withSemicolon,
  formSubPath,
}: Props) {
  const showThan = rule.parameters[formSubPath]?.operator
    ? [
        RuleParametersComparison.GreaterThan,
        RuleParametersComparison.LessThan,
      ].includes(rule.parameters[formSubPath]?.operator!)
    : null

  const showTo = !!rule.parameters[formSubPath]?.toSeconds

  return (
    <>
      <b>
        {Utils.Rule.getComparisonText(rule.parameters[formSubPath]?.operator)}
      </b>{' '}
      {showThan && <Span>than</Span>}{' '}
      <Span>
        <>
          <b>
            {Utils.DateTime.secondsToHours(
              rule.parameters[formSubPath]?.fromSeconds!,
            )}
          </b>

          {!showTo && withSemicolon && ','}
        </>
      </Span>{' '}
      {showTo && (
        <Span>
          <Span>and</Span>{' '}
          <b>
            {Utils.DateTime.secondsToHours(
              rule.parameters[formSubPath]?.toSeconds!,
            )}
          </b>
          {withSemicolon && ','}
        </Span>
      )}
    </>
  )
}
