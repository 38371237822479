import { MAX_MINUTES_IN_HOUR } from 'constants/dailyWorkingHours'

import { GlobalSettingsFormState } from './types'

export function mapFormStateToServerData(
  formState: GlobalSettingsFormState,
  hasAutoShiftNameFeature: boolean,
) {
  const {
    disableEmployeeProfileEdit,
    allowFutureManualTimecardApprove,
    earlyClockIn,
    autoShiftName,
    earlyClockInLimitHours,
    earlyClockInLimitMinutes,
    sendScheduleEmails,
    showWagesToManagers,
    disableEmployeeBreaks,
    allowDepartmentManagerMutualApprove,
    calendarStartDay,
  } = formState

  const earlyClockInLimit =
    earlyClockInLimitHours * MAX_MINUTES_IN_HOUR + earlyClockInLimitMinutes

  return {
    allowDepartmentManagerMutualApprove,
    disableEmployeeProfileEdit,
    disableEmployeeBreaks,
    allowFutureManualTimecardApprove,
    earlyClockIn,
    earlyClockInLimit,
    sendScheduleEmails,
    showWagesToManagers,
    calendarStartDay,
    ...(hasAutoShiftNameFeature && {
      autoShiftName,
    }),
  }
}
