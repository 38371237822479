import React, { useEffect, useState } from 'react'

import { Flex } from 'components/ui/__v2__/Grid'
import { SearchInput } from 'components/ui/__v3__/SearchInput'

import { useDebouncedValue } from 'hooks/useDebouncedValue'

import { i18n } from 'i18n'

type Props = {
  value: string
  onChange: (value: string) => void
}

// NOTE: Not sure this is the purest solution
export function Search({ value, onChange }: Props) {
  const [displayedValue, setDisplayedValue] = useState<string>(value)

  const debouncedValue = useDebouncedValue(displayedValue)

  useEffect(() => {
    if (debouncedValue !== value) {
      onChange(debouncedValue)
    }
  }, [debouncedValue, onChange, value])

  return (
    <Flex fontWeight="initial">
      <SearchInput
        placeholder={i18n('actions.search')}
        value={displayedValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setDisplayedValue(event.target.value)
        }
      />
    </Flex>
  )
}
