import React from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { AvailableEmployeeForShiftJobByCursorNode } from 'API/Employee/GraphQL'

import { WarningPopover } from 'components/blocks/__v2__'
import { Cells } from 'components/blocks/__v3__/Table'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Table } from 'components/ui/__v3__'

import { useI18n } from 'hooks/useI18n'

import { TRANSLATIONS } from 'i18n'

import Utils from 'services/Utils'

import {
  EmployeePicker,
  EmployeePickerReturnOption,
  EmployeeSinglePickerArgs,
} from '../../../../EmployeePicker'
import { AssignButton, AssignedIcon } from '../../Controls'

type ShiftAssignI18n = typeof TRANSLATIONS.shiftAssign.candidates

type Props = {
  candidates: AvailableEmployeeForShiftJobByCursorNode[]
  cantAssignMore: boolean
  isDraftAssigned: (item: AvailableEmployeeForShiftJobByCursorNode) => boolean
  filters: {
    employee?: EmployeePickerReturnOption
  }
  filterHandlers: {
    employee: EmployeeSinglePickerArgs['onSelect']
  }
  handlers: {
    onAssign: (item: AvailableEmployeeForShiftJobByCursorNode) => void
    onRevertAssignment: (item: AvailableEmployeeForShiftJobByCursorNode) => void
  }
}

type Column = ColumnDef<AvailableEmployeeForShiftJobByCursorNode, any>

export function useCandidatesTable({
  candidates,

  isDraftAssigned,
  cantAssignMore,

  filters,
  filterHandlers,

  handlers,
}: Props) {
  const t = useI18n<ShiftAssignI18n>('shiftAssign.candidates')

  const Warning: Column = {
    id: 'warining',
    header: ' ',
    cell: ({
      row: {
        original: {
          assignmentWillViolateWeeklyHoursLimit,
          assignmentWillViolateDailyHoursLimit,
        },
      },
    }) => {
      if (
        assignmentWillViolateWeeklyHoursLimit ||
        assignmentWillViolateDailyHoursLimit
      )
        return (
          <WarningPopover
            iconSize={14}
            popoverContent={
              <Flex flexDirection="column">
                {assignmentWillViolateWeeklyHoursLimit && (
                  <Span>{t('assignmentWillViolateWeeklyHoursLimit')}</Span>
                )}
                {assignmentWillViolateDailyHoursLimit && (
                  <Span>{t('assignmentWillViolateDailyHoursLimit')}</Span>
                )}
              </Flex>
            }
            trigger="click"
          />
        )
      return null
    },
    meta: {
      width: '18px',
    },
  }

  const FullName: Column = {
    id: 'fullName',
    header: () => (
      <Table.Headers.Filter>
        <Span fontWeight="bold">{t('columns.fullName')}</Span>
        <EmployeePicker
          value={filters.employee ?? null}
          onSelect={filterHandlers.employee}
        />
      </Table.Headers.Filter>
    ),
    cell: ({
      row: {
        original: { employee },
      },
    }) => {
      return <Cells.FullName profile={employee.user.profile} />
    },
    meta: {
      width: '220px',
      maxWidth: '220px',
    },
  }

  const Rate: Column = {
    header: t('columns.rate'),

    accessorFn: ({ employeeJob }) =>
      Utils.Monetary.displayWage({
        wage: employeeJob.wage,
        wageType: employeeJob.wageType,
      }),
  }

  const ShiftsCount: Column = {
    header: t('columns.shifts'),

    accessorFn: row => row.weekStatistics.shiftsCount,
  }

  const HoursCount: Column = {
    header: t('columns.hours'),

    accessorFn: row => row.weekStatistics.scheduledHours,
  }

  const Actions: Column = {
    header: ' ',
    cell: ({ row: { original } }) => {
      const alreadyAssigned = isDraftAssigned(original)
      if (alreadyAssigned)
        return (
          <Flex justifyContent="center">
            <AssignedIcon />
          </Flex>
        )
      return (
        <AssignButton
          disabled={cantAssignMore}
          existing={false}
          onClick={() => handlers?.onAssign(original)}
        />
      )
    },
  }

  const columns = [Warning, FullName, Rate, ShiftsCount, HoursCount, Actions]

  return useReactTable({
    getCoreRowModel: getCoreRowModel(),
    data: candidates,
    columns,
    meta: {
      actionable: true,
      slim: true,
    },
  })
}
