import React, { useMemo } from 'react'

import { API } from 'API'

import { isEmpty } from 'lodash'

import { Popover } from 'components/ui/__v2__/Popover'
import { Select } from 'components/ui/__v3__/Select'

import { MAX_NAME_LENGTH } from 'constants/ids'

import { i18n } from 'i18n'

import { generateClassifierDefaultOptions } from './helpers'
import { ClassifierLabelContainer, ClassifierPopoverContent } from './styles'
import { ClassifierOptionType, ClassifiersPickerProps } from './types'

export function ClassifiersPicker({
  isDisabled,
  placeholder,
  loadingText,
  excludeClassifiers,
  ...rest
}: ClassifiersPickerProps) {
  const {
    loadClassifiers,
    classifiers,
    loading,
    refetch,
  } = API.Classifiers.picker()

  const defaultOptions = useMemo(() => {
    if (classifiers) {
      const options = generateClassifierDefaultOptions(
        classifiers,
        excludeClassifiers,
      )

      return options
    }

    return []
  }, [classifiers, excludeClassifiers])

  const handleLoadOptions = async (inputValue: string, callback: Function) => {
    const refetchedClassifiers = await refetch(inputValue)
    const newOptions = !isEmpty(refetchedClassifiers)
      ? generateClassifierDefaultOptions(
          refetchedClassifiers,
          excludeClassifiers,
        )
      : []
    callback(newOptions)
  }

  function renderLoadingMessage() {
    return loadingText ?? `${i18n('common.loading')}...`
  }

  function formatClassifierOptionLabel(option: ClassifierOptionType) {
    const { classifier } = option
    const classifierInfoString = `${
      classifier.name
    }: ${classifier.condition?.in?.join(', ')}`

    return (
      // @ts-ignore TODO: Popover to TS
      <Popover
        content={
          <ClassifierPopoverContent>
            {classifierInfoString}
          </ClassifierPopoverContent>
        }
        disabled={classifierInfoString.length < MAX_NAME_LENGTH}
      >
        <ClassifierLabelContainer>
          {classifierInfoString}
        </ClassifierLabelContainer>
      </Popover>
    )
  }

  return (
    <Select
      // @ts-ignore  TODO: remove ts-ignore when TS will be added to the Select
      async
      defaultOptions={defaultOptions}
      formatOptionLabel={formatClassifierOptionLabel}
      isDisabled={isDisabled}
      isLoading={loading}
      loadOptions={handleLoadOptions}
      loadingMessage={renderLoadingMessage}
      placeholder={placeholder}
      withPortal
      onMenuOpen={loadClassifiers}
      {...rest}
    />
  )
}
