import { ShiftValidationEssentialAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

export function getShiftConflictsByEmployeeId(
  employeeId: number,
  shiftValidations: ShiftValidationEssentialAPIItem[],
) {
  return (
    shiftValidations.find(
      shiftValidation => shiftValidation.employee.id === employeeId,
    )?.conflicts ?? []
  )
}
