import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Branch } from 'Types/app'

import { LocationsPicker } from 'components/blocks/__v2__'
import { Flex } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

type Props<T extends FieldValues> = ControllerBaseProps<T> &
  LabelProps & { isDisabled?: boolean }

type LocationPickerOption = {
  id: string
  label: string
  value: string
  location: Branch
}

// NOTE: We need to thin because react-hook-form is not able to clone large objects
export function thinFormLocation(option: LocationPickerOption) {
  return {
    id: option.id,
    label: option.label,
    value: option.value,
  }
}

export type FormLocationPickerReturn = ReturnType<typeof thinFormLocation>

/** @deprecated Pay attention, not all functionality */
export function FormLocationPicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
  isDisabled,
}: Props<T>) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const handleSelect = (option: LocationPickerOption) => {
    onChange(thinFormLocation(option))
  }

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      {/* TODO: Need to type underlying component */}
      <LocationsPicker
        isDisabled={isDisabled}
        value={value}
        // @ts-ignore
        onBlur={onBlur}
        // @ts-ignore
        onChange={handleSelect}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}
