import 'validate.config'
import 'flatfile.config'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConnectedRouter } from 'connected-react-router'
import I18n from 'i18n-js'
import { StoreContext } from 'storeon/react'
import { QueryParamProvider } from 'use-query-params'

import { App } from 'components/App'
import ErrorBoundary from 'components/ErrorBoundary'

import AbilityProvider from 'services/ACL/AbilityProvider'
import { apolloClient } from 'services/Apollo'
import { AppContextProvider } from 'services/AppContext'
import HathorProvider from 'services/Hathor/HathorProvider'
import history from 'services/History'
import { storeon } from 'services/StoreOn'
import { LOCALE_KEY } from 'services/StoreOn/Locale/locale'

import createStore from 'store'

const store = createStore()
// TODO: move to service?
const queryClient = new QueryClient()

// Locale initialization here because of circular dependencies
I18n.locale = storeon.get()[LOCALE_KEY]

ReactDOM.render(
  <StoreContext.Provider value={storeon}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryParamProvider
          ReactRouterRoute={Route}
          stringifyOptions={{ skipNull: true }}
        >
          <ApolloProvider client={apolloClient}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
              <AppContextProvider>
                <AbilityProvider>
                  <HathorProvider>
                    <ErrorBoundary>
                      <App />
                    </ErrorBoundary>
                  </HathorProvider>
                </AbilityProvider>
              </AppContextProvider>
            </QueryClientProvider>
          </ApolloProvider>
        </QueryParamProvider>
      </ConnectedRouter>
    </Provider>
  </StoreContext.Provider>,
  document.getElementById('app'),
)
