import React, { useCallback, useMemo } from 'react'

import { LayoutProps, SpaceProps } from 'styled-system'

import { API } from 'API'

import { Select } from 'components/ui/__v3__/Select'

import { createpDebounce } from 'helpers/debounce'

import { i18n } from 'i18n'

import {
  employeeMemberToOption,
  exposeEmployeeOption,
  matchNameOrCode,
} from './helpers'
import { EmployeePickerArgs, EmployeePickerInternalOption } from './types'

export function EmployeePicker({
  areas,
  value,
  onSelect,
  isMulti,
  labelContent,
  ...rest
}: EmployeePickerArgs & LayoutProps & SpaceProps) {
  const { fetching, loadEmployees, employees } = API.Employee.byCursorLazy({
    filters: { areas },
  })

  const handleChange = isMulti
    ? (option: EmployeePickerInternalOption[] | null) =>
        onSelect(option?.map(exposeEmployeeOption) ?? null)
    : (option: EmployeePickerInternalOption | null) =>
        onSelect(option ? exposeEmployeeOption(option) : option)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInput = useCallback(
    createpDebounce(async (inputValue: string, callback: Function) => {
      const searchedEmployees = await loadEmployees({
        nameOrCode: inputValue,
      })

      const newOptions = (searchedEmployees ?? []).map(employeeMemberToOption)
      callback(newOptions)
    }),
    [loadEmployees],
  )

  const defaultOptions = useMemo(() => employees.map(employeeMemberToOption), [
    employees,
  ])

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect={!isMulti}
      defaultOptions={defaultOptions}
      filterOption={matchNameOrCode}
      isLoading={fetching}
      isMulti={isMulti}
      isSearchable
      labelContent={labelContent}
      loadOptions={handleInput}
      placeholder={i18n('employeePicker.nameOrCode')}
      value={value}
      withPortal
      onChange={handleChange}
      onMenuOpen={() => loadEmployees({})}
      {...rest}
    />
  )
}
