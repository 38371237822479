import React, { useCallback } from 'react'
import { OptionProps } from 'react-select'

import { API } from 'API'

import { Select } from 'components/ui/__v3__/Select'
import { Option } from 'components/ui/__v3__/Select/components'
import { PickerProps } from 'components/ui/__v3__/Select/types'

import { createpDebounce } from 'helpers/debounce'

import Utils from 'services/Utils'

import { timeBucketChildrenEntityToOption } from './helpers'
import { TimeBucketChildrenOption } from './types'

const SEARCH_DEBOUNCE_TIMEOUT = 350

type Props = {
  parentIds?: string[]
  withParentsNames?: boolean
  withArchived?: boolean
} & Omit<
  PickerProps,
  | 'async'
  | 'closeMenuOnSelect'
  | 'defaultOptions'
  | 'formatOptionLabel'
  | 'isLoading'
  | 'loadOptions'
  | 'onMenuOpen'
>

export function TimeBucketChildrenPicker({
  parentIds,
  withParentsNames,
  withArchived = false,
  isMulti,
  onChange,
  ...rest
}: Props) {
  const {
    timeBucketsChildren,
    timeBucketsChildrenLoading,
    loadTimeBucketsChildren,
    refetchTimeBucketsChildren,
  } = API.TimeBucket.childrenPicker()

  const defaultOptions =
    timeBucketsChildren.map(timeBucketChildrenEntityToOption) ?? []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadOptions = useCallback(
    createpDebounce(
      async (inputValue: string, callback: Function) => {
        const refetchedData = await refetchTimeBucketsChildren({
          inputValue,
          parentIds,
          archived: withArchived,
        })
        const newOptions = refetchedData.map(timeBucketChildrenEntityToOption)

        callback(newOptions)
      },
      undefined,
      SEARCH_DEBOUNCE_TIMEOUT,
    ),
    [refetchTimeBucketsChildren],
  )

  const handleMenuOpen = () => {
    loadTimeBucketsChildren({ parentIds, archived: withArchived })
  }

  // NOTE: to refresh the default values
  const handleInputChange = (
    inputValue: string,
    action: { action: string },
  ) => {
    if (action.action !== 'input-change' || inputValue) return

    refetchTimeBucketsChildren({ archived: withArchived })
  }

  const formatOptionLabel = useCallback(
    (option: TimeBucketChildrenOption) =>
      withParentsNames
        ? Utils.TimeBucket.getTimeBucketJoinedName({
            timeBucketChild: option.timeBucketChild,
          })
        : option.label,
    [withParentsNames],
  )

  const renderOption = useCallback(
    ({ data, ...rest }: OptionProps<TimeBucketChildrenOption, true>) => {
      const newData = {
        ...data,
        label: formatOptionLabel(data),
      }

      return <Option data={newData} {...rest} />
    },
    [formatOptionLabel],
  )

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect={!isMulti}
      customComponents={{ Option: renderOption }}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptionLabel}
      isClearable
      isLoading={timeBucketsChildrenLoading}
      isMulti={isMulti}
      isSearchable
      loadOptions={handleLoadOptions}
      onChange={onChange}
      onInputChange={handleInputChange}
      onMenuOpen={handleMenuOpen}
      {...rest}
    />
  )
}
