import React from 'react'
import { FieldValues } from 'react-hook-form'

import { SelectOption } from 'Types/common'

import { Select } from 'components/ui/__v3__/Select'

import { useFormSelectAttributes } from './hooks'
import { FormSelectProps } from './types'

import { InputLabel } from '../../Input'
import { LabelText } from '../../Input/LabelText'
import { Error } from '../components'

export function FormSelect<T extends FieldValues, Option = SelectOption>({
  options,
  returnOptionObject = true,
  control,
  name,
  labelText,
  placeholder,
  required,
  wrapper,
  isClearable,
  isSearchable,
  isDisabled,
  withPortal,
  isMulti,
  ...rest
}: FormSelectProps<T, Option>) {
  const {
    ref,
    selectedValue,
    onBlur,
    handleChange,
    error,
  } = useFormSelectAttributes({
    control,
    name,
    isMulti: !!isMulti,
    returnOptionObject,
    options,
  })

  return (
    <InputLabel>
      <LabelText labelText={labelText} required={required} />
      <Select
        {...rest}
        {...wrapper}
        // @ts-ignore
        closeMenuOnSelect={!isMulti}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        options={options}
        placeholder={placeholder}
        ref={ref}
        value={selectedValue}
        withPortal={withPortal}
        onBlur={onBlur}
        onChange={handleChange}
      />
      {error && <Error error={error} />}
    </InputLabel>
  )
}
