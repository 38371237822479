import React, { useCallback, useMemo, useState } from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'

import { Duration } from 'luxon'

import noop from 'lodash/noop'
import range from 'lodash/range'

import { Popover } from 'components/ui/__v2__/Popover'

import { TIME_MASK } from 'constants/settings'

import { getIsPm, localeWithMeridiem } from 'helpers/date'

import useMount from 'hooks/useMount'

import { DAY_SECONDS, HALF_OF_DAY, LOCALE_STRING_FORMAT } from './constants'
import { useDisableLabel } from './hooks'
import {
  AmPm,
  ClockIcon,
  Container,
  Disabled,
  Opener,
  TimeInput,
  TimeInputWrapper,
  TimeOption,
  TimeOptions,
} from './styles'
import {
  formatInputValue,
  getStartDate,
  normalizeAmPm,
  parseTimeString,
} from './utils'

function TimeSelect({
  disabledSeconds,
  disableMeridiem,
  isDisabled,
  step,
  value,
  timezone,
  onChange,
  onBlur,
  ...rest
}) {
  const startDate = getStartDate(timezone)
  const date = startDate.plus({ seconds: value })
  const isPm = getIsPm(date)

  const withMeridiem = disableMeridiem ? false : localeWithMeridiem()

  const [popoverVisible, setPopoverVisible] = useState(false)
  const [inputValue, setInputValue] = useState(formatInputValue(date))
  const [isEdit, setIsEdit] = useState(false)

  useMount(() => setInputValue(formatInputValue(date)))

  const containerRef = useDisableLabel()

  const handleChange = useCallback(
    seconds => {
      onChange(seconds)
    },
    [onChange],
  )

  const changeAmPm = () => {
    const nextValue = normalizeAmPm(value)

    handleChange(Math.abs(nextValue))
  }

  const handleSelectTime = useCallback(
    seconds => () => {
      handleChange(seconds)
      setPopoverVisible(false)
    },
    [handleChange],
  )

  const handleClickOutside = () => {
    setPopoverVisible(false)
    onBlur()
  }

  const handleClickOpener = () => setPopoverVisible(true)

  const handleChangeInput = ({ target }) => {
    setInputValue(target.value)
  }

  const handleFocusInput = () => {
    setInputValue(formatInputValue(date))
    setIsEdit(true)
  }

  const handleBlurInput = useCallback(
    ({ target }) => {
      setIsEdit(false)

      const time = target.value

      const [hoursString, minutesString] = parseTimeString(time)

      const hours = parseInt(
        hoursString ? hoursString.replace(/_/g, '0') : '00',
        10,
      )
      const minutes = parseInt(
        minutesString ? minutesString.replace(/_/g, '0') : '00',
        10,
      )

      if (hours > 23 || minutes > 59) {
        return
      }

      const duration = Duration.fromObject({
        hours: withMeridiem && !isPm && hours === 12 ? 0 : hours,
        minutes,
      })

      const initialSeconds = duration.as('seconds')

      let seconds = initialSeconds

      if (withMeridiem) {
        seconds += isPm && initialSeconds < HALF_OF_DAY ? HALF_OF_DAY : 0

        if (isPm && initialSeconds === HALF_OF_DAY) {
          seconds = HALF_OF_DAY
        }
      }

      handleChange(seconds)
      onBlur()
    },
    [isPm, withMeridiem, handleChange, onBlur],
  )

  const getDisabledOptions = useCallback(
    seconds =>
      seconds > disabledSeconds?.after || seconds < disabledSeconds?.before,
    [disabledSeconds?.after, disabledSeconds?.before],
  )

  const options = useMemo(() => {
    const result = []
    const secondsOptions = range(0, DAY_SECONDS, step)

    for (const seconds of secondsOptions) {
      const isOptionDisabled =
        disabledSeconds.after || disabledSeconds.before
          ? getDisabledOptions(seconds)
          : false

      if (!isOptionDisabled) {
        result.push(
          <TimeOption
            key={seconds}
            selected={value === seconds}
            onClick={handleSelectTime(seconds)}
          >
            {startDate.plus({ seconds }).toLocaleString(LOCALE_STRING_FORMAT)}
          </TimeOption>,
        )
      }
    }

    return result
  }, [
    disabledSeconds.after,
    disabledSeconds.before,
    getDisabledOptions,
    handleSelectTime,
    startDate,
    step,
    value,
  ])

  return (
    <Container
      isEdit={isEdit}
      popoverisVisible={popoverVisible}
      ref={containerRef}
      {...rest}
    >
      {isDisabled && <Disabled />}

      <Popover
        content={
          <TimeOptions width={containerRef.current?.clientWidth}>
            {options}
          </TimeOptions>
        }
        interactive
        noArrow
        offset={[0, 3]}
        p={0}
        placement="bottom-start"
        visible={popoverVisible}
        onClickOutside={handleClickOutside}
      >
        <TimeInputWrapper>
          <MaskedInput
            mask={TIME_MASK}
            render={(ref, props) => <TimeInput ref={ref} {...props} />}
            tabIndex={isDisabled && '-1'}
            value={isEdit ? inputValue : formatInputValue(date)}
            onBlur={handleBlurInput}
            onChange={handleChangeInput}
            onFocus={handleFocusInput}
          />
        </TimeInputWrapper>
      </Popover>

      {withMeridiem && <AmPm onClick={changeAmPm}>{isPm ? 'PM' : 'AM'}</AmPm>}

      <Opener
        // disabled={isDisabled}
        onClick={!isDisabled ? handleClickOpener : null}
      >
        <ClockIcon />
      </Opener>
    </Container>
  )
}

TimeSelect.defaultProps = {
  disableMeridiem: false,
  disabledSeconds: {
    after: null,
    before: null,
  },
  isDisabled: false,
  step: 1800,
  value: null,
  timezone: 'local',
  onBlur: noop,
  onChange: noop,
}

TimeSelect.propTypes = {
  disableMeridiem: PropTypes.bool,
  disabledSeconds: PropTypes.object,
  isDisabled: PropTypes.bool,
  step: PropTypes.number,
  timezone: PropTypes.string,
  value: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

export default TimeSelect
