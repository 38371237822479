import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'

import { createStructuredSelector } from 'reselect'

import { ACCESS_LEVEL } from 'constants/ids'

import * as routes from 'helpers/routes'

import { useAppContext } from 'hooks'

import _ from 'i18n'

import GlobalStyle from 'imports/GlobalStyle'

import { Archived } from 'pages/Archived'
import { Auth } from 'pages/Auth'
import EmployeeAppPage from 'pages/EmployeeApp'
import EmployerAppPage from 'pages/EmployerApp'

import { AuthAppState } from 'services/Auth'
import { getBrandConfig, theme } from 'services/Brand'

import {
  getAsEmployee,
  getIsBranchSwitching,
  getIsViewerArchivedInActiveBranch,
} from 'store/selectors/viewer'

import ThemeProvider from 'theme/Provider'

import { ConfirmationModal } from './modals/__v3__'
import { LocaleModal } from './modals/Settings'
import { Flex } from './ui/__v2__/Grid'
import { ToastContainer } from './ui/__v3__/Toast/ToastContainer'
import { BranchSwitching } from './ui/BranchSwitching'
import { SpinnerBig } from './ui/Spinner'
import { Toasts } from './ui/Toasts'

import 'react-toastify/dist/ReactToastify.css'

// TODO: Maybe move to brand config
const {
  manifest,
  favicon,
}: { manifest: string; favicon: string } = getBrandConfig()

type Selectors = {
  asEmployee: boolean
  isBranchSwitching: boolean
  isViewerArchivedInActiveBranch: boolean
}

export function App() {
  const { loading, authenticated, viewer } = useAppContext()

  const {
    asEmployee,
    isBranchSwitching,
    isViewerArchivedInActiveBranch,
  } = useSelector(
    createStructuredSelector<unknown, Selectors>({
      isBranchSwitching: getIsBranchSwitching,
      asEmployee: getAsEmployee,
      isViewerArchivedInActiveBranch: getIsViewerArchivedInActiveBranch,
    }),
  )
  const isEmployee = viewer.accessLevel === ACCESS_LEVEL.employee

  const renderApp = () => {
    if (!authenticated) {
      const { pathname, search } = window.location
      const state: AuthAppState = { from: pathname + search }

      return (
        <Redirect
          to={{
            pathname: routes.authPath(),
            state,
          }}
        />
      )
    }

    if (isViewerArchivedInActiveBranch) {
      return <Redirect to={routes.notPartPath()} />
    }

    return (
      <Route path={routes.rootPath()}>
        {isEmployee || asEmployee ? <EmployeeAppPage /> : <EmployerAppPage />}
      </Route>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <Helmet>
        <title>{_('brand')}</title>
        <link href={manifest} rel="manifest" />
        <link href={favicon} rel="icon" type="image/png" />
      </Helmet>

      {loading ? (
        <SpinnerBig global />
      ) : (
        <Flex flexDirection="column">
          <Switch>
            <Route component={Auth} exact path={routes.authPath()} />
            <Route component={Archived} exact path={routes.notPartPath()} />

            {renderApp()}
          </Switch>

          <Toasts />
          <ToastContainer icon={false} />
          <ConfirmationModal />
          <LocaleModal />

          {isBranchSwitching && <BranchSwitching />}
        </Flex>
      )}
    </ThemeProvider>
  )
}
