import { useArchiveShifts } from './useArchiveShifts'
import { useCopyShiftConflicts } from './useCopyShiftConflicts'
import { useCopyShifts } from './useCopyShifts'
import { useCreateShiftsWithAssignments } from './useCreateShiftsWithAssignments'
import { useShiftById } from './useShiftById'
import { useShiftsByCursor } from './useShiftsByCursor'
import { useShiftsByCursorWithShiftJobs } from './useShiftsByCursorWithShiftJobs'
import { useUpdateShift } from './useUpdateShift'
import { useUpdateShifts } from './useUpdateShifts'

export * from './types'

export const ShiftAPI = {
  byCursor: useShiftsByCursor,
  byCursorWithShiftJobs: useShiftsByCursorWithShiftJobs,
  byId: useShiftById,
  archiveMany: useArchiveShifts,
  update: useUpdateShift,
  updateMany: useUpdateShifts,
  createMany: useCreateShiftsWithAssignments,
  shiftConflicts: useCopyShiftConflicts,
  copyShifts: useCopyShifts,
}
