import styled, { css } from 'styled-components'

type Args = { color: string }
const colorCss = ({ color }: Args) =>
  css`
    background: ${color};
  `

export const Color = styled.div<Args>`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  ${colorCss}
`
