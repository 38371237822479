import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth0Client, IdToken, RedirectLoginOptions } from '@auth0/auth0-spa-js'
import { SignInByAuth0Mutation, SignOutByAuth0Mutation } from 'GraphQL/User'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import { auth0 } from 'constants/config'

import { i18n } from 'i18n'

import LocalStorage from 'services/LocalStorage'
import { showToast } from 'services/Toasts'

import { gatewayHttpLink } from './Apollo/Links/gatewayHttpLink'
import { isDawamyEnvironment } from './Brand'

export const UPDATE_AUTH_EVENT = 'updateAuth'
const AUTH_ERROR_KEY = 'authError'

export type AuthAppState = {
  from?: string
}

interface IdTokenClaims extends IdToken {
  clusterId?: string
}

// TODO: add unit tests for auth
class Auth {
  private readonly auth0Client = new Auth0Client({
    clientId: auth0.clientId,
    domain: auth0.domain,
    cacheLocation: 'localstorage', // Problems with cookies on localhost
    authorizationParams: {
      prompt: 'select_account',
      audience: auth0.audience,
      redirect_uri: auth0.redirectUri,
    },
  })

  private readonly apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: false,
  })

  private readonly TIME_TO_MAKE_API_CALL = 5 * 1000

  private accessToken = ''

  private auth0AccessToken = ''

  private clusterId = ''

  private refreshAccessTokenPromise?: Promise<string>

  private isAuthenticated = false

  private isLoading = true

  private from = ''

  constructor() {
    this.exchangeAuth0ForGatewayToken.bind(this)
    this.getAccessToken.bind(this)
    this.getAuthHeaders.bind(this)
    this.getRefreshedAccessToken.bind(this)
    this.refreshAccessToken.bind(this)
    this.validateAccessToken.bind(this)
    this.logoutFromAuth0.bind(this)
    this.logout.bind(this)

    this.initialize()
  }

  private async initialize() {
    const authError = LocalStorage.getItem(AUTH_ERROR_KEY)

    if (authError) {
      // Timeout to make sure that locale is set
      setTimeout(() => {
        showToast({
          type: 'error',
          title: i18n('auth.error.title'),
          content: authError,
        })
      }, 0)

      LocalStorage.removeItem(AUTH_ERROR_KEY)
    }

    const auth0SearchString =
      location.search.includes('state=') &&
      (location.search.includes('code=') || location.search.includes('error='))

    if (auth0SearchString) {
      try {
        const {
          appState,
        } = await this.auth0Client.handleRedirectCallback<AuthAppState>()

        this.from = appState?.from ?? ''
      } catch (error) {
        const errorMessage =
          (error as Error).message ===
          // This message comes from custom action https://manage.auth0.com/dashboard/us/workaxle-dev/actions/library
          'This aunthentication method is not allowed'
            ? i18n('auth.error.notAllowed')
            : i18n('auth.error.invalidCredentials')

        LocalStorage.setItem(AUTH_ERROR_KEY, errorMessage)

        return this.logoutFromAuth0()
      }
    }

    const isAuth0Authenticated = await this.auth0Client.isAuthenticated()

    if (isAuth0Authenticated) {
      const idTokenClaims:
        | IdTokenClaims
        | undefined = await this.auth0Client.getIdTokenClaims()

      this.clusterId = idTokenClaims?.clusterId ?? ''
      // Set apollo links here, to make sure that first request will be with clusterId
      this.apolloClient.setLink(
        ApolloLink.from([
          setContext(async (_, { headers }) => ({
            headers: {
              ...headers,
              'cluster-id': this.clusterId,
            },
          })),
          gatewayHttpLink(),
        ]),
      )
      this.auth0AccessToken = await this.auth0Client.getTokenSilently()

      const accessToken = await this.exchangeAuth0ForGatewayToken()

      if (accessToken) {
        this.accessToken = accessToken
        this.isAuthenticated = true
      }
    }

    this.isLoading = false
    // To prevent safari bug -----------------------------------------------
    const isSafari = navigator.userAgent.includes('Safari')
    const timeout = isSafari ? 100 : 0
    return setTimeout(() => {
      window.postMessage(UPDATE_AUTH_EVENT, '*')
    }, timeout)
    // ---------------------------------------------------------------------
  }

  // ==========================================================================
  // Public methods
  // ==========================================================================
  public loginWithRedirect(options: RedirectLoginOptions<any>) {
    this.auth0Client.loginWithRedirect(options)
  }

  public getState() {
    return {
      isLoading: this.isLoading,
      isAuthenticated: this.isAuthenticated,
      from: this.from,
    }
  }

  public async getAccessToken() {
    const validToken = this.validateAccessToken()

    return validToken ?? this.getRefreshedAccessToken()
  }

  public getAuth0Token() {
    return this.auth0AccessToken
  }

  public async getAuthHeaders() {
    const token = this.isAuthenticated ? await this.getAccessToken() : ''

    return {
      Authorization: `Bearer ${token}`,
      'cluster-id': this.clusterId,
    }
  }

  // TODO: add spinner
  public async logout() {
    LocalStorage.clear()
    await this.logoutFromWorkAxle()
    this.logoutFromAuth0()
  }

  // ==========================================================================
  // Private methods
  // ==========================================================================
  private async logoutFromWorkAxle() {
    try {
      await this.apolloClient.mutate<
        MutationData<'signOutByAuth0'>,
        Gateway.MutationSignOutByAuth0Args
      >({
        mutation: SignOutByAuth0Mutation,
        variables: { token: this.auth0AccessToken },
      })
    } catch (error) {
      // TODO: possible edge case, add logging
    }
  }

  private async logoutFromAuth0() {
    this.auth0Client.logout({
      logoutParams: {
        returnTo: auth0.redirectUri,
        federated: isDawamyEnvironment(),
      },
    })
  }

  private validateAccessToken() {
    if (!this.accessToken) {
      return null
    }

    try {
      const { exp } = jwtDecode<JwtPayload>(this.accessToken)
      const expiresIn = Number(exp) * 1000
      // + TIME_TO_MAKE_API_CALL to make sure that we will not receive Unathorized response
      const isTokenValid = Date.now() + this.TIME_TO_MAKE_API_CALL < expiresIn

      return isTokenValid ? this.accessToken : null
    } catch (error) {
      return null
    }
  }

  private getRefreshedAccessToken() {
    if (!this.refreshAccessTokenPromise) {
      this.refreshAccessToken()
    }

    return this.refreshAccessTokenPromise!
  }

  private async refreshAccessToken() {
    this.refreshAccessTokenPromise = this.exchangeAuth0ForGatewayToken()
    this.accessToken = await this.refreshAccessTokenPromise
    this.refreshAccessTokenPromise = undefined
  }

  private async exchangeAuth0ForGatewayToken() {
    try {
      const { data } = await this.apolloClient.mutate<
        MutationData<'signInByAuth0'>,
        Gateway.MutationSignInByAuth0Args
      >({
        mutation: SignInByAuth0Mutation,
        variables: { input: { token: this.auth0AccessToken } },
      })

      return data?.signInByAuth0?.accessToken ?? ''
    } catch (error) {
      const errorMessage = (error as Error).message

      LocalStorage.setItem(AUTH_ERROR_KEY, errorMessage)

      this.logoutFromAuth0()

      return '' // To satisfy type checking
    }
  }
}

export const AuthService = new Auth()
