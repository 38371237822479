import React, { useCallback, useMemo } from 'react'

import { API } from 'API'
import { DateTime } from 'luxon'

import { first, last } from 'lodash'

import { InfoPopover } from 'components/blocks/__v2__'
import { Divider } from 'components/ui/__v2__/Divider'
import { Flex, SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { Button } from 'components/ui/__v3__'
import { Spinner } from 'components/ui/Spinner'

import { TimeEntryKind } from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import { useConfirmationModal, useI18n } from 'hooks'

import { useTimesheetPermissions } from './hooks/useTimesheetPermissions'
import { TimesheetRecord, TimesheetSummary } from './components'
import {
  approved,
  disableRecordActions,
  discarded,
  draftTimesheetRecordGuard,
  existingTimesheetRecordGuard,
  formItemToCreatePayload,
  formItemToUpdatePayload,
  getEffectiveRolesOptionsByDay,
  getTimesheetInfo,
  pendingOrFuture,
  serverEntryToUpdatePayload,
  toId,
} from './helpers'
import { useTimesheetForm } from './hooks'
import {
  AddIcon,
  Day,
  DayHeader,
  Days,
  IconButton,
  Layout,
  NoRecords,
  RecordsList,
  RightPane,
  SummaryHeader,
  TimesheetStatus,
} from './styles'
import { Props, TimesheetFormState } from './types'

import { Error as ErrorHelper } from '../HookForm/components'

/**
 * @param days - The days are expected in the EEs timezone
 * @param employeeJobs - The positions, that both the EE and the manager can select from
 */
export function Timesheeet({
  employeeId,
  employeeName,
  employeeJobs,
  days,
}: Props) {
  const t = useI18n('weeklyTimesheets')

  // ============================== CALLS ==============================================

  const { timeEntries, loading: loadingEntries } = API.TimeCard.byCursorGql({
    paging: { size: PAGE_SIZE['999'] },
    filter: {
      employeeId: { eq: employeeId },
      kind: { in: [TimeEntryKind.WeeklyTimesheet] },
      startOn: {
        between: [first(days)!.toISODate(), last(days)!.toISODate()],
      },
    },
  })

  const noEligibleRoles = useMemo(() => employeeJobs.length === 0, [
    employeeJobs,
  ])

  const { createUpdate, upserting } = API.TimeCard.createUpdateMany()
  const { updateTimeEntries, updating } = API.TimeCard.updateMany()
  const { discardTimeEntries, discarding } = API.TimeCard.discardMany()
  const { approveTimeEntries, approving } = API.TimeCard.approveMany()
  const { restoreTimeEntries, restoring } = API.TimeCard.restoreMany()
  const { deleteTimeEntries, deleting } = API.TimeCard.deleteMany()

  const callsInProgress = useMemo(
    () =>
      loadingEntries ||
      upserting ||
      updating ||
      discarding ||
      approving ||
      restoring ||
      deleting,
    [
      approving,
      deleting,
      discarding,
      loadingEntries,
      restoring,
      updating,
      upserting,
    ],
  )

  const { timeSheetState, hasSavedEntries } = useMemo(
    () => getTimesheetInfo(timeEntries),
    [timeEntries],
  )

  const {
    canAddRecords,

    canSaveAsEmployee,
    canSubmitAsEmployee,

    canUnsubmitAsManager,
    canSaveAndApproveAsManager,
    canForceSubmitAsManager,
    canUnapproveAsManager,
    canDiscardAsManager,
    canRestoreAsManager,

    canDeleteWeeklyTimesheet,

    isEmployer,
  } = useTimesheetPermissions({
    timeSheetState,
    hasSavedEntries,
  })

  const positionOptions = useMemo(
    () => getEffectiveRolesOptionsByDay(employeeJobs, days),
    [days, employeeJobs],
  )

  const canAdd = useCallback(
    (dayIndex: number) =>
      canAddRecords && !callsInProgress && positionOptions[dayIndex].length > 0,
    [callsInProgress, canAddRecords, positionOptions],
  )

  // ====================================================================================

  const { getConfirmation } = useConfirmationModal()

  const {
    control,
    fieldsByDay,
    formTimeEntries,
    handleFormSubmit,
    append,
    invalid,
    getFieldState,
    remove,
    resetForm,
    allChangesSaved,
    allTimeEntriesAreDrafts,
    undo,
    errors,
    trigger,
  } = useTimesheetForm({
    serverTimeEntries: timeEntries,
    days,
  })

  // NOTE: here the form values are checked for draft
  //       so that the entire form can be cleared at once
  const disableDeleteButton = useMemo(
    () =>
      formTimeEntries.some(timeEntry =>
        disableRecordActions(timeEntry, canAddRecords),
      ),
    [formTimeEntries, canAddRecords],
  )

  // ====================================================================================

  const handleAddClick = useCallback(
    (day: DateTime, dayIndex: number) => {
      append({
        employeeId,
        startDate: day,
        startSeconds: day
          .plus({ hours: DEFAULT_START_HRS })
          .diff(day, 'seconds').seconds,
        endSeconds: day
          .plus({ hours: DEFAULT_START_HRS + DEFAULT_DURATION_HRS })
          .diff(day, 'seconds').seconds,
        position: positionOptions[dayIndex][0] || null,
        earningType: null,
        // We need this to let manager edit the record if they create it
        submittedAt: isEmployer ? DateTime.now().toISO() : undefined,
      })
    },
    [employeeId, positionOptions, isEmployer, append],
  )

  const handleDeleteTimesheets = useCallback(
    async (ids: number[]) => {
      const deletedTimesheetsCount = ids.length

      const confirmation = await getConfirmation({
        title: t('confirmation.delete.title', {
          count: deletedTimesheetsCount,
        }),
        content: t('confirmation.delete.content', {
          count: deletedTimesheetsCount,
        }),
        confirmText: t('confirmation.delete.confirmText'),
      })

      if (!confirmation) return

      await deleteTimeEntries({ ids })
    },
    [deleteTimeEntries, getConfirmation, t],
  )

  const onValidEmployeeSaveForm = useCallback(
    async ({ entries }: TimesheetFormState) => {
      const createPayloads = entries
        .filter(draftTimesheetRecordGuard)
        .map(formItemToCreatePayload)
      const updatePayloads = entries
        .filter(existingTimesheetRecordGuard)
        .filter((_, idx) => getFieldState(`entries.${idx}`).isDirty)
        .filter(pendingOrFuture)
        .map(formItemToUpdatePayload)

      await createUpdate({
        createInput: {
          timeEntries: createPayloads,
          submit: isEmployer,
        },
        updateInput: {
          timeEntries: updatePayloads,
          submit: isEmployer,
        },
      })
    },
    [createUpdate, getFieldState, isEmployer],
  )

  const onValidManagerSaveForm = useCallback(
    async ({ entries }: TimesheetFormState) => {
      await onValidEmployeeSaveForm({ entries })

      const idsToApprove = entries
        .filter(existingTimesheetRecordGuard)
        .filter(pendingOrFuture)
        .map(toId)

      if (idsToApprove.length > 0) {
        await approveTimeEntries({
          ids: idsToApprove,
        })
      }
    },
    [approveTimeEntries, onValidEmployeeSaveForm],
  )

  const onSubmissionIntent = useCallback(
    async (action: 'employeeSubmit' | 'managerSubmit' | 'managerUnsubmit') => {
      const confirmation = await getConfirmation({
        title: t(`confirmation.${action}.title`),
        content: t(`confirmation.${action}.content`),
        confirmText: t(`confirmation.${action}.confirmText`),
      })

      if (!confirmation) return

      const updatePayloads = timeEntries
        .filter(pendingOrFuture)
        .map(serverEntryToUpdatePayload)

      const submit = action === 'employeeSubmit' || action === 'managerSubmit'
      await updateTimeEntries({ timeEntries: updatePayloads, submit })
    },
    [getConfirmation, t, timeEntries, updateTimeEntries],
  )

  const onMutateClick = useCallback(
    async (
      mutation:
        | 'approve'
        | 'discard'
        | 'unapprove'
        | 'restore'
        | 'remove'
        | 'delete',
    ) => {
      switch (mutation) {
        case 'approve':
          await approveTimeEntries({
            ids: timeEntries.filter(pendingOrFuture).map(toId),
          })
          break
        case 'discard':
          await discardTimeEntries({
            ids: timeEntries.filter(pendingOrFuture).map(toId),
          })
          break
        case 'unapprove':
          await restoreTimeEntries({
            ids: timeEntries.filter(approved).map(toId),
          })
          break
        case 'restore':
          await restoreTimeEntries({
            ids: timeEntries.filter(discarded).map(toId),
          })
          break
        case 'delete':
          await handleDeleteTimesheets(
            timeEntries.filter(existingTimesheetRecordGuard).map(toId),
          )
          break
        case 'remove':
          resetForm()
          break
        default:
          throw new Error('Invalid mutation')
      }
    },
    [
      approveTimeEntries,
      discardTimeEntries,
      restoreTimeEntries,
      handleDeleteTimesheets,
      resetForm,
      timeEntries,
    ],
  )

  // ====================================================================================
  const renderDayTimesheetItems = useCallback(
    (dayIndex: number) => {
      if (loadingEntries)
        return (
          <NoRecords>
            <Spinner size={14} />
          </NoRecords>
        )

      if (fieldsByDay[dayIndex].length === 0)
        return <NoRecords>{t('noRecords')}</NoRecords>

      return (
        <RecordsList>
          {fieldsByDay[dayIndex].map(field => (
            <TimesheetRecord
              control={control}
              disabled={disableRecordActions(field, canAddRecords)}
              index={field.index}
              key={field.formId}
              positionOptions={positionOptions[dayIndex]}
              trigger={trigger}
              onDeleteExisting={id => handleDeleteTimesheets([id])}
              onRemoveDraft={remove}
              onUndoChanges={undo}
            />
          ))}
        </RecordsList>
      )
    },
    [
      canAddRecords,
      control,
      fieldsByDay,
      loadingEntries,
      positionOptions,
      remove,
      t,
      trigger,
      undo,
      handleDeleteTimesheets,
    ],
  )

  const renderDays = useCallback(() => {
    if (noEligibleRoles) {
      return <NoRecords>{t('noEligibleRoles')}</NoRecords>
    }

    return days.map((day, dayIndex) => (
      <Day key={day.toISODate()}>
        <DayHeader>
          <Flex>
            <Span as="h3">{day.toFormat(DATE_FROMAT)}</Span>
            <IconButton
              disabled={!canAdd(dayIndex)}
              type="button"
              onClick={() => handleAddClick(day, dayIndex)}
            >
              <AddIcon />
            </IconButton>
          </Flex>
          <Divider />
        </DayHeader>
        {renderDayTimesheetItems(dayIndex)}
      </Day>
    ))
  }, [
    canAdd,
    days,
    handleAddClick,
    noEligibleRoles,
    renderDayTimesheetItems,
    t,
  ])

  const renderActions = useCallback(() => {
    if (isEmployer) {
      return (
        <>
          {/* Whatever the manager saves, gets approves as well */}
          {canSaveAndApproveAsManager && (
            <Button
              disabled={invalid || callsInProgress}
              type="button"
              width={140}
              onClick={handleFormSubmit(onValidManagerSaveForm)}
            >
              {t('actions.saveAndApprove')}
            </Button>
          )}

          {/* Manager can take control at any point */}
          {canForceSubmitAsManager && (
            <Button
              disabled={invalid || callsInProgress}
              type="button"
              width={140}
              onClick={() => onSubmissionIntent('managerSubmit')}
            >
              {t('actions.forceSubmit')}
            </Button>
          )}

          {/* Manager can unapprove all timecards */}
          {canUnapproveAsManager && allChangesSaved && (
            <Button
              disabled={invalid || callsInProgress}
              type="button"
              width={140}
              onClick={() => onMutateClick('unapprove')}
            >
              {t('actions.unapprove')}
            </Button>
          )}

          {/* Manager can discard all timecards */}
          {canDiscardAsManager && allChangesSaved && (
            <Button
              disabled={invalid || callsInProgress}
              type="button"
              width={140}
              onClick={() => onMutateClick('discard')}
            >
              {t('actions.discard')}
            </Button>
          )}

          {/* Manager can restore all timecards */}
          {canRestoreAsManager && allChangesSaved && (
            <Button
              disabled={invalid || callsInProgress}
              type="button"
              width={140}
              onClick={() => onMutateClick('restore')}
            >
              {t('actions.restore')}
            </Button>
          )}

          {/* Manager can release it back to EE, as long as all changes saved and only the pending ones */}
          {canUnsubmitAsManager && allChangesSaved && (
            <Button
              disabled={invalid || callsInProgress}
              type="button"
              width={140}
              onClick={() => onSubmissionIntent('managerUnsubmit')}
            >
              {t('actions.unsubmit')}
            </Button>
          )}

          {/* Manager can delete all unapproved timecards */}
          {canDeleteWeeklyTimesheet && (
            <Button
              disabled={invalid || callsInProgress || disableDeleteButton}
              error
              type="button"
              width={140}
              onClick={() =>
                allTimeEntriesAreDrafts
                  ? onMutateClick('remove')
                  : onMutateClick('delete')
              }
            >
              <Span mr={2}>
                {allTimeEntriesAreDrafts
                  ? t('actions.remove')
                  : t('actions.delete')}
              </Span>

              {disableDeleteButton && (
                <InfoPopover
                  iconSize={14}
                  popoverContent={t('tooltip.delete')}
                />
              )}
            </Button>
          )}
        </>
      )
    }

    return (
      <>
        {/* EE can save as long as they have control and there are unsaved changes */}
        {canSaveAsEmployee && (
          <Button
            disabled={invalid || callsInProgress}
            type="button"
            width={140}
            onClick={handleFormSubmit(onValidEmployeeSaveForm)}
          >
            {t('actions.save')}
          </Button>
        )}

        {/* EE can submit once all changes saved */}
        {canSubmitAsEmployee && allChangesSaved && (
          <Button
            disabled={invalid || callsInProgress}
            type="button"
            width={140}
            onClick={() => onSubmissionIntent('employeeSubmit')}
          >
            {t('actions.submit')}
          </Button>
        )}
      </>
    )
  }, [
    allChangesSaved,
    callsInProgress,
    canDiscardAsManager,
    canForceSubmitAsManager,
    canRestoreAsManager,
    canSaveAndApproveAsManager,
    canSaveAsEmployee,
    canSubmitAsEmployee,
    canUnapproveAsManager,
    canUnsubmitAsManager,
    canDeleteWeeklyTimesheet,
    disableDeleteButton,
    allTimeEntriesAreDrafts,
    invalid,
    isEmployer,
    handleFormSubmit,
    onMutateClick,
    onSubmissionIntent,
    onValidEmployeeSaveForm,
    onValidManagerSaveForm,
    t,
  ])

  // ====================================================================================

  return (
    <Layout>
      <form>
        <Days>
          {isEmployer && (
            <SummaryHeader>
              <Span as="h3">
                {t('employeesTimesheet', { name: employeeName })}
              </Span>
              <Divider />
            </SummaryHeader>
          )}
          {renderDays()}
        </Days>
      </form>
      <RightPane>
        {!noEligibleRoles && (
          <>
            <SummaryHeader>
              <Span as="h3">{t('summary.title')}</Span>
              <Divider />
            </SummaryHeader>
            <TimesheetSummary control={control} days={days} />

            <Divider />
            {!loadingEntries && errors.entries?.root && (
              <ErrorHelper error={errors.entries.root} />
            )}

            <SpacedColumn alignItems="end" justifyContent="space-between">
              {renderActions()}
              <TimesheetStatus status={timeSheetState}>
                {t(`state.${timeSheetState}`)}
              </TimesheetStatus>
            </SpacedColumn>
          </>
        )}
      </RightPane>
    </Layout>
  )
}

const DATE_FROMAT = 'cccc, LLL d'
const DEFAULT_DURATION_HRS = 8
const DEFAULT_START_HRS = 9
