import { useEffect, useRef } from 'react'

export function useClickOutside(elRef, callback) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    const handleClickOutside = event => {
      if (elRef.current && !elRef.current.contains(event.target)) {
        callbackRef.current(event)
      }
    }

    document.addEventListener('click', handleClickOutside, { capture: true })

    return () =>
      document.removeEventListener('click', handleClickOutside, {
        capture: true,
      })
  }, [elRef])
}
