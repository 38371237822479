import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import { isNil } from 'lodash'

import { EmployeePickerReturnOption } from 'components/blocks/__v3__'
import {
  FieldValuePairState,
  FieldValuePairType,
} from 'components/blocks/__v3__/HookForm/FormCustomCvFieldsWithValues/types'

import { CustomFieldFilterOperator } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import {
  AvailableEmployeesForShiftJobByCursorQuery,
  AvailableEmployeesForShiftJobByCursorQueryData,
} from '../GraphQL'

type Props = {
  shiftJobId: string
  paging: Gateway.CursorPaging
  options: Gateway.AvailableEmployeesForShiftJobOptions
  filter: {
    employee?: EmployeePickerReturnOption
    customCvFields?: FieldValuePairState[]
  }
}

export function useAvailableEmployeesForShiftJobByCursor(
  { shiftJobId, paging, options, filter }: Props,
  queryOptions: Partial<QueryHookOptions>,
) {
  const { data, loading, refetch } = useApolloQuery<
    AvailableEmployeesForShiftJobByCursorQueryData,
    Gateway.QueryAvailableEmployeesForShiftJobByCursorArgs
  >(AvailableEmployeesForShiftJobByCursorQuery, {
    ...queryOptions,
    variables: {
      shiftJobId,
      paging,
      options,
      filter: {
        employeeId: filter?.employee
          ? Utils.GraphQL.eqFilter(filter.employee.value)
          : undefined,
        customFields: mapCustomFieldValuePairsToGraphQlFilter(
          filter?.customCvFields,
        ),
      },
    },
    fetchPolicy: 'network-only',
  })

  const availableEmployees = data?.availableEmployeesForShiftJobByCursor?.edges
    ? data?.availableEmployeesForShiftJobByCursor?.edges.map(edge => edge.node)
    : []

  return {
    availableEmployees,
    loading,
    refetch,
    pageInfo: data?.availableEmployeesForShiftJobByCursor?.pageInfo,
  }
}

// TODO: Make BE use same format for Global search and here

function mapCustomFieldValuePairsToGraphQlFilter(
  fieldValuePairs: FieldValuePairState[] | undefined,
): Gateway.AvailableEmployeesForShiftJobCustomFieldFilter | undefined {
  if (!fieldValuePairs) return undefined

  const nonBlankPairs = fieldValuePairs.filter(optionProvidedGuard)

  if (nonBlankPairs.length === 0) return undefined

  return {
    conditions: nonBlankPairs.map(({ field, value }) => ({
      fieldId: String(field.customCvField.id),
      fieldValue: String(value),
    })),
    operator: CustomFieldFilterOperator.And,
  }
}

function optionProvidedGuard(
  option: FieldValuePairState,
): option is FieldValuePairType {
  return !isNil(option.field) && !isNil(option.value)
}
