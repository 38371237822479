import React, { useMemo } from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { ConflictType } from 'constants/gatewayGraphQL'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

type Props = {
  conflictType: ConflictType
  rule: ShiftValidationRuleAPIItem
}

type DaysOfWeek = [0, 1, 2, 3, 4, 5, 6]

// TODO: add common i18n for this component & RuleRenderer->DayOfweek
export function DayOfWeek({ conflictType, rule }: Props) {
  const weekdaysString = useMemo(
    () =>
      (rule.parameters.dayOfWeek?.daysOfWeek as DaysOfWeek)
        ?.map(dayOfWeek =>
          i18n(`workflowBuilder.deck.ruleWeekdays.${dayOfWeek}`),
        )
        .join(', '),
    [rule],
  )

  return (
    <>
      If employees are assigned on <b>{weekdaysString}, </b>invoke a{' '}
      <b>{Utils.Conflict.getConflictTypeText(conflictType)}.</b>
    </>
  )
}
