import { differenceWith, intersectionWith } from 'lodash'

import apiCall from 'services/API'

import {
  CREATE_SHIFTS_JOBS,
  DELETE_SHIFTS_JOBS,
  UPDATE_SHIFTS_JOBS,
  updateShiftsJobsInclude,
} from './shifts'

const hasAccess = ({ viewerHasAccess }) => viewerHasAccess

function prepareShiftJobsToUpdate(shift, selectedRoles) {
  const rolesNewData = selectedRoles.filter(hasAccess)
  const rolesOldData = shift.shiftsJobs.filter(hasAccess)
  const createdRoles = selectedRoles.filter(
    ({ shiftJobId, id }) =>
      !shiftJobId ||
      !rolesOldData.some(
        oldRole => oldRole.job.id === id && oldRole.id === shiftJobId,
      ),
  )

  const createShiftJobsData = createdRoles.map(
    ({ amount, id, departmentId }) => ({
      type: 'shifts_jobs',
      attributes: {
        quantity: amount,
      },
      relationships: {
        job: {
          data: {
            type: 'jobs',
            id,
          },
        },
        department: {
          data: {
            type: 'departments',
            id: departmentId,
          },
        },
        shift: {
          data: {
            type: 'shifts',
            id: shift.id,
          },
        },
      },
    }),
  )

  const deleteShiftJobsData = differenceWith(
    rolesOldData,
    rolesNewData,
    ({ id: oldShiftJobId, job }, { shiftJobId, id: newRoleId }) =>
      oldShiftJobId === shiftJobId && newRoleId === job.id,
  ).map(({ id }) => ({
    id,
    type: 'shifts_jobs',
  }))

  const updateShiftJobsData = intersectionWith(
    rolesOldData,
    rolesNewData,
    (
      { quantity, job, id: oldShiftJobId },
      { amount, id: newRoleId, shiftJobId },
    ) =>
      quantity !== amount &&
      job.id === newRoleId &&
      oldShiftJobId === shiftJobId,
  ).map(({ id, schedules }) => {
    const { amount } = selectedRoles.find(({ shiftJobId }) => shiftJobId === id)

    const employees = schedules.map(s => ({
      id: Number(s.employee.id),
      type: 'employees',
    }))

    const shouldKeepEmployees =
      employees.length > 0 && employees.length <= amount

    return {
      id,
      type: 'shifts_jobs',
      attributes: {
        quantity: amount,
      },
      relationships: {
        employees: {
          data: shouldKeepEmployees ? employees : [],
        },
      },
    }
  })

  return {
    deleteShiftJobsData,
    updateShiftJobsData,
    createShiftJobsData,
    createdRoles,
  }
}

const createShiftJobs = (createShiftJobsData, createdRoles) =>
  apiCall({
    endpoint: '/shifts_jobs',
    method: 'POST',
    query: {
      data: createShiftJobsData,
      include: 'shift',
    },
    types: CREATE_SHIFTS_JOBS,
    payload: {
      roles: createdRoles,
    },
  })

const deleteShiftJobs = (shift, deleteShiftJobsData) =>
  apiCall({
    endpoint: '/shifts_jobs',
    method: 'DELETE',
    query: {
      data: deleteShiftJobsData,
    },
    types: DELETE_SHIFTS_JOBS,
    payload: {
      deletedItems: deleteShiftJobsData,
      relationId: shift.id,
    },
  })

export const updateShiftJobs = updateShiftJobsData =>
  apiCall({
    endpoint: '/shifts_jobs',
    method: 'PATCH',
    query: {
      include: updateShiftsJobsInclude.join(),
      data: updateShiftJobsData,
    },
    types: UPDATE_SHIFTS_JOBS,
  })

export const editShiftRoles = (shift, selectedRoles) => dispatch => {
  const {
    deleteShiftJobsData,
    updateShiftJobsData,
    createShiftJobsData,
    createdRoles,
  } = prepareShiftJobsToUpdate(shift, selectedRoles)

  let deleteResponse
  let updateResponse
  let createResponse

  if (deleteShiftJobsData.length) {
    deleteResponse = dispatch(deleteShiftJobs(shift, deleteShiftJobsData))
  }

  if (updateShiftJobsData.length) {
    updateResponse = dispatch(updateShiftJobs(updateShiftJobsData))
  }

  if (createShiftJobsData.length) {
    createResponse = dispatch(
      createShiftJobs(createShiftJobsData, createdRoles),
    )
  }

  return updateResponse?.ok || deleteResponse?.ok || createResponse?.ok || false
}
