import { useApolloLazyQuery } from 'API/services/Apollo'

import {
  SortingDirection,
  TimetypeSortingField,
} from 'constants/gatewayGraphQL'

import {
  PickerTimeTypesByOffsetQuery,
  PickerTimeTypesByOffsetQueryData,
} from './GraphQL'

export function usePickerTimeTypes() {
  const [loadTimeTypes, { data, loading, refetch }] = useApolloLazyQuery<
    PickerTimeTypesByOffsetQueryData,
    Gateway.QueryTimetypesByOffsetArgs
  >(PickerTimeTypesByOffsetQuery, {
    variables: {
      paging: { limit: 999, offset: 0 },
      sorting: [
        {
          field: TimetypeSortingField.Name,
          direction: SortingDirection.Desc,
        },
      ],
    },
    fetchPolicy: 'network-only',
  })

  const timeTypes = data?.timetypesByOffset?.rows ?? []

  return {
    loadTimeTypes,
    timeTypes,
    fetching: loading,
    refetch,
  }
}
