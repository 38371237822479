import isEmpty from 'lodash/isEmpty'

import { PAGE_SIZE } from 'constants/pagination'

import { formatISOToYearMonthDayHoursMillis } from 'helpers/date'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getWeek } from 'store/selectors/employees/schedules'
import { getBranchId } from 'store/selectors/viewer'

export const INIT = 'schedule/INIT'
export const INIT_DONE = 'schedule/INIT_DONE'
export const TOGGLE_SHOW_BUDGET = 'schedule/TOGGLE_SHOW_BUDGET'
export const LOAD_RANGE = createAsyncAction('schedule/LOAD_RANGE')
export const PUBLISH = createAsyncAction('schedule/PUBLISH')
export const SHIFT_WEEK = 'schedule/SHIFT_WEEK'
export const UPDATE_TIMERS = createAsyncAction('schedule/UPDATE_TIMERS')
export const AUTHORIZE = createAsyncAction('schedule/AUTHORIZE')
export const UNAUTHORIZE = createAsyncAction('schedule/UNAUTHORIZE')
export const SELECT_TIMESHEETS_CELL = 'schedule/SELECT_TIMESHEETS_CELL'
export const SELECT_TIMESHEETS_ROW = 'schedule/SELECT_TIMESHEETS_ROW'
export const SELECT_TIMESHEETS_COLUMN = 'schedule/SELECT_TIMESHEETS_COLUMN'
export const REMEMBER_SELECTED_SCHEDULES =
  'schedule/REMEMBER_SELECTED_SCHEDULES'
export const LOAD_SCHEDULES_LOGS = createAsyncAction(
  'schedule/LOAD_SCHEDULES_LOGS',
)
export const CREATE_EXPORT_SCHEDULE_TOKEN = createAsyncAction(
  'schedules/CREATE_EXPORT_TOKEN',
)
export const LOAD_SCHEDULE_TIMER_INFO = createAsyncAction(
  'schedules/LOAD_SCHEDULE_TIMER_INFO',
)
export const CLEAR_TIME_ENTRIES_INFO = 'schedules/CLEAR_TIME_ENTRIES_INFO'
export const TOGGLE_ACTUAL_DELETE = 'schedule/TOGGLE_ACTUAL_DELETE'
export const ACTUAL_DELETE = createAsyncAction('schedules/ACTUAL_DELETE')
export const CREATE_TIME_ENTRY = createAsyncAction(
  'schedules/CREATE_TIME_ENTRY',
)
export const LOAD_TIME_ENTRIES = createAsyncAction('schedule/LOAD_TIME_ENTRIES')
export const UPDATE_FILTER_JOBS = 'schedules/UPDATE_FILTER_JOBS'
export const TOGGLE_VIEW_SORT_OPTION = 'schedules/TOGGLE_VIEW_SORT_OPTIONS'
export const SELECT_FILTER_EMPLOYEES = 'schedules/SELECT_FILTER_EMPLOYEES'

export const init = () => ({ type: INIT })

export const initDone = () => ({ type: INIT_DONE })

export const includeSchedules = [
  'shifts_job.shift.pauses',
  'shifts_job.shift.shiftsJobs',
  'shifts_job.job.department',
  'employee',
  'shift_covers.accepted_employee',
  'shift_covers.from_employee',
  'shift_covers.to_employees',
  'shift_covers.schedule',
  'accepted_shift_trades.accepted_schedule',
  'accepted_shift_trades.from_schedule',
  'accepted_shift_trades.to_schedules',
  'from_shift_trades.accepted_schedule',
  'from_shift_trades.from_schedule',
  'from_shift_trades.to_schedules',
  'absences.employee',
  'absences.schedule',
  'timeEntry.info',
  'timeEntry.pauseTimers',
  'timeEntry.schedule',
  'timeEntry.pauseTimers.pause',
  'timeEntry.authorisedBy',
  'timeEntry.logs',
  'schedulesLogs',
  'timeEntry.logs.manager.user.profile',
  'timeEntry.logs.pauseTimer',
  'timeEntry.notes',
  'timeEntry.job',
  'schedulesLogs.manager.user.profile',
  'schedulesLogs.pauseTimer',
  'schedulesLogs.schedule',
  'schedulesLogs.timeEntry',
  'timeEntry.pauseTimers.notes',
  'timeEntry.statistics',
]

export const loadSchedules = (from, to, withReplace = false) => (
  dispatch,
  getState,
) => {
  const branchId = getBranchId(getState())
  const week = getWeek(getState())
  const startAt = isEmpty(from) ? week.start : from
  const endAt = isEmpty(to) ? week.end : to
  dispatch(
    apiCall({
      endpoint: `/branches/${branchId}/relationships/schedules`,
      types: LOAD_RANGE,
      payload: { withReplace },
      query: {
        include: includeSchedules.join(),
        filter: {
          shifts_start_at: formatISOToYearMonthDayHoursMillis(startAt),
          shifts_finish_at: formatISOToYearMonthDayHoursMillis(endAt),
        },
        page: { size: PAGE_SIZE['50'] },
      },
      paged: true,
    }),
  )
}

export const loadSchedulesLogs = scheduleId =>
  apiCall({
    endpoint: `/schedules/${scheduleId}/relationships/logs`,
    types: LOAD_SCHEDULES_LOGS,
    query: {
      page: { size: PAGE_SIZE['50'] },
      include: [
        'manager',
        'manager.user',
        'manager.user.profile',
        'pauseTimer',
        'schedule',
        'timeEntry',
      ].join(),
    },
    paged: true,
  })

export const shiftWeek = week => ({ type: SHIFT_WEEK, payload: { week } })

export const selectTimesheetsRow = (employeeId, departmentId) => ({
  type: SELECT_TIMESHEETS_ROW,
  payload: { employeeId, departmentId },
})

export const selectTimesheetsColumn = dayIndex => ({
  type: SELECT_TIMESHEETS_COLUMN,
  payload: { dayIndex },
})

export const rememberSelectedSchedules = (selectedSchedules, isAuthorized) => ({
  type: REMEMBER_SELECTED_SCHEDULES,
  payload: { selectedSchedules, isAuthorized },
})

export const shiftWeekAndLoad = newWeek => dispatch => {
  dispatch(loadSchedules(newWeek.start, newWeek.end))
  dispatch(loadTimeEntries(newWeek.start, newWeek.end))
  dispatch(shiftWeek(newWeek))
}

const timeEntriesInclude = ['user', 'schedule', 'logs.manager.user.profile']

const timersInclude = [
  ...timeEntriesInclude,
  'pause',
  'pauseTimers',
  'job.department',
  'timeBucket',
  'timeBucket.timeBucketParent',
]

export const updateTimers = data => {
  return apiCall({
    method: 'PATCH',
    endpoint: '/timers',
    types: UPDATE_TIMERS,
    query: {
      data,
      include: timersInclude.join(),
    },
  })
}

export const updateTimeEntries = (data, timeCardId) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/time_entries/${timeCardId}/relationships/job`,
    types: UPDATE_TIMERS,
    query: {
      data,
      include: timeEntriesInclude.join(),
    },
  })
}

export const authCall = (data, needRemember = true) => {
  return apiCall({
    method: 'POST',
    endpoint: '/time_entries/authorise',
    types: AUTHORIZE,
    query: {
      data,
      include: 'logs.manager.user.profile',
    },
    payload: { selectedSchedules: data, needRemember },
  })
}

export const unAuthCall = (data, needRemember = true) => {
  return apiCall({
    method: 'POST',
    endpoint: '/time_entries/unauthorise',
    types: UNAUTHORIZE,
    query: {
      data,
      include: 'logs.manager.user.profile',
    },
    payload: { selectedSchedules: data, needRemember },
  })
}

export const createExportScheduleToken = ({
  areas,
  startAt,
  endAt,
  unpublished,
  published,
  companyId,
  page,
}) => {
  return apiCall({
    method: 'POST',
    endpoint: '/token',
    types: CREATE_EXPORT_SCHEDULE_TOKEN,
    isRaw: true,
    payload: {
      areas,
      startAt,
      endAt,
      unpublished,
      published,
      companyId,
      page,
    },
  })
}

export const loadScheduleTimerInfo = scheduleId => {
  return apiCall({
    endpoint: `/schedules/${scheduleId}/timer`,
    types: LOAD_SCHEDULE_TIMER_INFO,
    query: {
      include: 'info',
    },
  })
}

export const clearTimeEntriesInfo = () => ({
  type: CLEAR_TIME_ENTRIES_INFO,
})

export const toggleShowBudget = () => ({
  type: TOGGLE_SHOW_BUDGET,
})

export const toggleActualDelete = () => ({
  type: TOGGLE_ACTUAL_DELETE,
})

export const actualTimeDelete = timeEntryId => {
  return apiCall({
    method: 'DELETE',
    endpoint: `/time_entries/${timeEntryId}`,
    types: ACTUAL_DELETE,
  })
}

const createTimeEntryInclude = [
  'employee',
  'schedule',
  'pauseTimers',
  'job',
  'logs',
  'logs.manager',
  'logs.manager.user',
  'logs.manager.user.profile',
  'timerNotes',
  'timerNotes.manager',
  'timerNotes.manager.user',
  'timerNotes.manager.user.profile',
  'timeBucket',
  'timeBucket.timeBucketParent',
]

export const createTimeEntry = data => {
  return apiCall({
    method: 'POST',
    endpoint: '/time_entries',
    query: {
      include: createTimeEntryInclude.join(),
      data,
    },
    types: CREATE_TIME_ENTRY,
  })
}

const includeTimeEntries = [
  'employee',
  'schedule',
  'pauseTimers',
  'job',
  'logs',
  'logs.manager',
  'logs.manager.user',
  'logs.manager.user.profile',
  'authorisedBy',
  'notes',
  'pauseTimers.notes',
  'statistics',
]

export const loadTimeEntries = (
  from = null,
  to = null,
  employeeIds = [],
  filterState = null,
) => (dispatch, getState) => {
  const branchId = getBranchId(getState())

  const stateFilter = filterState ? { 'filter[state][eq]': filterState } : {}

  const employeesFilter = isEmpty(employeeIds)
    ? {}
    : { 'filter[employee_id][eq]': employeeIds }

  const week = getWeek(getState())
  const startAt = isEmpty(from) ? week.start : from
  const endAt = isEmpty(to) ? week.end : to

  return dispatch(
    apiCall({
      endpoint: `/time_entries`,
      types: LOAD_TIME_ENTRIES,
      query: {
        include: includeTimeEntries.join(),
        'filter[range][between]': [
          formatISOToYearMonthDayHoursMillis(startAt),
          formatISOToYearMonthDayHoursMillis(endAt),
        ],
        ...stateFilter,
        'filter[branch_id][eq]': branchId,
        ...employeesFilter,
        page: { size: PAGE_SIZE['50'] },
      },
      paged: true,
    }),
  )
}
