import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { Span } from 'components/ui/__v2__/Grid'

import { ConflictType } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import { HoursRangeCondition } from './HoursRangeCondition'

type Props = {
  conflictType: ConflictType
  rule: ShiftValidationRuleAPIItem
}

// TODO: add common i18n for this component & RuleRenderer->SingleShiftLength
export function SingleShiftLengthThreshold({ conflictType, rule }: Props) {
  return (
    <>
      If shift length are{' '}
      <HoursRangeCondition
        formSubPath="shiftLengthThreshold"
        rule={rule}
        withSemicolon
      />{' '}
      <Span>invoke a</Span>{' '}
      <b>{Utils.Conflict.getConflictTypeText(conflictType)}.</b>
    </>
  )
}
