import { useApolloLazyQuery } from 'API/services/Apollo'

import { PAGE_SIZE } from 'constants/pagination'

import { GroupEssentialAPIItem } from './GraphQL/types'
import { GroupsPickerQuery, GroupsPickerQueryData } from './GraphQL'

export function useGroupsPicker() {
  const [
    loadGroups,
    { loading: groupsLoading, data, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    GroupsPickerQueryData,
    Gateway.QueryGroupsByCursorArgs
  >(GroupsPickerQuery, {
    variables: {
      paging: { limit: PAGE_SIZE['10'] },
    },
    fetchPolicy: 'cache-first',
  })

  const refetchGroups = async (inputValue: string) => {
    if (!innerRefetch) return []

    const result = await innerRefetch({
      filter: { name: { iLike: inputValue } },
    })
    return dataToNodes(result.data)
  }

  const groups = dataToNodes(data)

  return {
    groups,
    groupsLoading,
    refetchGroups,
    loadGroups,
  }
}

function dataToNodes(data?: GroupsPickerQueryData): GroupEssentialAPIItem[] {
  return data?.groupsByCursor?.edges.map(edge => edge.node) ?? []
}
