import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { Span } from 'components/ui/__v2__/Grid'

import { ConflictType } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

type Props = {
  conflictType: ConflictType
  rule: ShiftValidationRuleAPIItem
}

// TODO: add common i18n for this component & RuleRenderer->EmployeeDaysOffWeekThreshold
export function EmployeeDaysOffWeekThreshold({ conflictType, rule }: Props) {
  return (
    <>
      If employee days off are{' '}
      <b>
        {Utils.Rule.getConditionText(
          rule.parameters.employeeDaysOffThreshold?.operator,
        )}
      </b>{' '}
      <b>
        {Utils.DateTime.secondsToDays(
          rule.parameters.employeeDaysOffThreshold?.fromSeconds!,
        )}
      </b>{' '}
      {rule.parameters.employeeDaysOffThreshold?.toSeconds && (
        <Span>
          <Span>and</Span>{' '}
          <b>
            {Utils.DateTime.secondsToDays(
              rule.parameters.employeeDaysOffThreshold.toSeconds,
            )}
          </b>
        </Span>
      )}{' '}
      <Span>within the period, invoke a</Span>{' '}
      <b>{Utils.Conflict.getConflictTypeText(conflictType)}.</b>
    </>
  )
}
