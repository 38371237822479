import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import differenceBy from 'lodash/differenceBy'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import noop from 'lodash/noop'

import BatchSelect from 'components/blocks/__v2__/BatchSelect'
import { Select } from 'components/ui/__v3__/Select'

import { BATCH_LOAD_MORE_SIZE } from 'constants/areas'

import { formatOptionLabel } from 'helpers/areas'
import { createDebounce } from 'helpers/debounce'
import { denormalize } from 'helpers/redux'

import { useAppContext } from 'hooks'

import _ from 'i18n'

import { showError } from 'services/API'
import { departmentToOption } from 'services/Options'

import { DEPARTMENTS_TYPE } from 'store/reducers/areas'

function DepartmentsPicker({
  cacheOptions,
  display,
  exclude,
  excludedManagerIds,
  include,
  initial,
  isDisabled,
  loadingText,
  placeholder,
  preload,
  value,
  onClear,
  onLoad,
  onInit,
  withBatchSelect,
  ...rest
}) {
  const { company } = useAppContext()
  const isEtfo = company.identity.Etfo

  const [input, setInput] = useState('')

  const denormalizeDepartmentsFromResult = useCallback(
    result => {
      const type = DEPARTMENTS_TYPE

      const data = result?.payload?.data
      const departmentIds = map(data?.[type], 'id')

      const options = isEmpty(departmentIds)
        ? []
        : denormalize(data, type, departmentIds)

      if (isEtfo) {
        options.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      }

      return options
    },
    [isEtfo],
  )

  const filterDepartments = useCallback(
    departments => {
      let entities = [...new Set(departments)]

      if (!isEmpty(exclude)) {
        const values = map(exclude, id => ({ id }))
        entities = differenceBy(entities, values, 'id')
      }

      return map(entities || [], departmentToOption)
    },
    [exclude],
  )

  const defaultOptions = useMemo(() => {
    return filterDepartments(initial.entities)
  }, [filterDepartments, initial.entities])

  const locationIds = useMemo(() => {
    if (isEmpty(rest.locationIds)) {
      return null
    }

    return isArray(rest.locationIds) ? rest.locationIds : [rest.locationIds]
  }, [rest.locationIds])

  const initOptions = useCallback(() => {
    if (!initial.isLoaded) {
      onInit({
        locationIds,
        display,
        excludedManagerIds,
        isEtfo,
      })
    }
  }, [
    initial.isLoaded,
    onInit,
    locationIds,
    display,
    excludedManagerIds,
    isEtfo,
  ])

  const prevLocationIds = useRef(locationIds)
  useEffect(() => {
    if (!isEqual(prevLocationIds.current, locationIds)) {
      onClear()
      prevLocationIds.current = locationIds
    }
  }, [locationIds, onClear])

  useEffect(() => {
    if (!isDisabled && preload && !initial.isLoaded) {
      initOptions()
    }
  }, [initOptions, initial.isLoaded, isDisabled, preload])

  const handleLoadOptions = useMemo(() => {
    return createDebounce(
      (inputValue, callback) => {
        if (!inputValue) {
          callback([])
        } else {
          onLoad({
            display,
            include,
            locationIds,
            name: inputValue,
            excludedManagerIds,
            isEtfo,
          }).then(result => {
            if (!result?.ok) {
              showError(result)
              callback([])
              return
            }
            const denormalizedDepartments = denormalizeDepartmentsFromResult(
              result,
            )
            callback(filterDepartments(denormalizedDepartments))
          })
        }
      },
      { leading: true, trailing: true },
    )
  }, [
    onLoad,
    display,
    include,
    locationIds,
    excludedManagerIds,
    isEtfo,
    denormalizeDepartmentsFromResult,
    filterDepartments,
  ])

  const renderLoadingMessage = useCallback(
    () => loadingText ?? `${_('common.loading')}...`,
    [loadingText],
  )

  const handleInputChange = useCallback(
    (inputValue, action) => {
      if (action.action === 'input-change' || action.action === 'set-value')
        setInput(inputValue)
    },
    [setInput],
  )

  const handleSelectFilteredDepartments = useCallback(() => {
    onLoad({
      display,
      include,
      locationIds,
      name: input,
      size: BATCH_LOAD_MORE_SIZE,
      excludedManagerIds,
      isEtfo,
    }).then(result => {
      if (!result?.ok) {
        showError(result)
        return
      }

      const denormalizedDepartments = denormalizeDepartmentsFromResult(result)
      const filtered = filterDepartments(denormalizedDepartments)

      rest.onChange(filtered)

      setInput('')
    })
  }, [
    onLoad,
    display,
    include,
    locationIds,
    input,
    excludedManagerIds,
    isEtfo,
    denormalizeDepartmentsFromResult,
    filterDepartments,
    rest,
  ])

  const pickerDisabled = isDisabled
  const isSelectAllButtonDisabled =
    !isEmpty(value) || pickerDisabled || initial.isLoading

  const hasBatchSelect = withBatchSelect && rest.isMulti

  return (
    <>
      <Select
        async
        cacheOptions={cacheOptions}
        closeMenuOnSelect={!rest.isMulti}
        defaultOptions={defaultOptions}
        formatOptionLabel={formatOptionLabel('department')}
        isDisabled={pickerDisabled}
        isLoading={initial.isLoading}
        isSearchable
        loadOptions={handleLoadOptions}
        loadingMessage={renderLoadingMessage}
        placeholder={placeholder}
        value={value}
        withBatchSelect={hasBatchSelect}
        withPortal
        onInputChange={handleInputChange}
        onMenuOpen={initOptions}
        {...rest}
      />

      {hasBatchSelect && (
        <BatchSelect
          handleSelect={handleSelectFilteredDepartments}
          hasInput={!!input}
          isDisabled={isSelectAllButtonDisabled}
          size={BATCH_LOAD_MORE_SIZE}
        />
      )}
    </>
  )
}

DepartmentsPicker.defaultProps = {
  cacheOptions: true,
  display: null,
  exclude: [],
  excludedManagerIds: null,
  include: null,
  initial: {},
  isDisabled: false,
  loadingText: null,
  locationIds: null,
  placeholder: null,
  preload: false,
  value: null,
  withBatchSelect: true,
  withPortal: true,
  onClear: noop,
  onInit: noop,
  onLoad: noop,
}

DepartmentsPicker.propTypes = {
  ...Select.propTypes,
  cacheOptions: PropTypes.bool,
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  exclude: PropTypes.array,
  excludedManagerIds: PropTypes.array,
  include: PropTypes.string,
  initial: PropTypes.object,
  isDisabled: PropTypes.bool,
  loadingText: PropTypes.string,
  locationIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  placeholder: PropTypes.string,
  preload: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  withBatchSelect: PropTypes.bool,
  withPortal: PropTypes.bool,
  onClear: PropTypes.func,
  onInit: PropTypes.func,
  onLoad: PropTypes.func,
}

export default DepartmentsPicker
