import { DateTime } from 'luxon'

import { Utils } from 'services/Utils'

import { DAY_SECONDS, HALF_OF_DAY, LOCALE_STRING_FORMAT } from './constants'

export function parseTimeString(time: string) {
  const withDelimiter = time.includes(':')

  return withDelimiter ? time.split(':') : Utils.Strings.splitAt(time, 2)
}

export function getStartDate(timezone: string) {
  return DateTime.local({ zone: timezone }).startOf('day')
}

export function formatInputValue(date: DateTime) {
  return date
    .toLocaleString(LOCALE_STRING_FORMAT)
    .replace(/ am/i, '')
    .replace(/ pm/i, '')
}

export function normalizeAmPm(value: number) {
  return value >= HALF_OF_DAY
    ? value - HALF_OF_DAY
    : DAY_SECONDS + value - HALF_OF_DAY
}
