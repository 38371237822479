import { useApolloMutation } from 'API/services/Apollo'

import { DeleteShiftTagMutation } from './GraphQL'

export function useRemoveShiftTag() {
  const [deleteShiftTag, { loading: removing }] = useApolloMutation<
    MutationData<'deleteShiftTag'>,
    Gateway.MutationDeleteShiftTagArgs
  >(DeleteShiftTagMutation, { translationKey: 'shiftTags.toasts.delete' })

  const remove = async (id: Gateway.MutationDeleteShiftTagArgs['id']) => {
    await deleteShiftTag({ variables: { id } })
  }

  return { remove, removing }
}
