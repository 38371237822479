import StyledPropTypes from '@styled-system/prop-types'

import styled from 'styled-components'
import { layout, position, space } from 'styled-system'

import { getSelectType } from './helpers'

export const SELECT_COMPONENT_STYLES = {
  menu: (provided, { theme, selectProps }) => ({
    ...provided,
    ...(selectProps.withBatchSelect && {
      marginTop: theme.selects.margin.default,
    }),
    zIndex: 9999,
  }),
  // theme parameter not passed
  menuPortal: provided => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided, { theme }) => ({
    ...provided,
    backgroundColor: theme.selects.color.light,
    borderRadius: theme.selects.border.radius,
  }),
  option: (provided, { theme, isSelected }) => {
    return {
      ...provided,
      color: theme.selects.color.dark,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      ...(isSelected && {
        color: theme.selects.color.light,
        backgroundColor: `${theme.layout.primary}`,
      }),

      '&:hover': {
        backgroundColor: theme.layout.highlight,
      },
    }
  },
  control: (
    provided,
    { theme, selectProps, isDisabled, isMulti, menuIsOpen },
  ) => ({
    ...provided,
    outline: theme.selects.outline,
    boxShadow: menuIsOpen ? theme.layout.primaryShadow : 'none',
    borderColor: menuIsOpen
      ? theme.selects.border.color.secondary
      : theme.selects.border.color.default,
    '&:hover': {
      borderColor: menuIsOpen
        ? theme.selects.hover.border.color.secondary
        : theme.selects.hover.border.color.default,
    },
    backgroundColor: theme.selects.backgroundColor.light,
    minHeight: theme.selects.height.default,

    ...(isDisabled && {
      backgroundColor: theme.selects.backgroundColor.disabled,
      borderColor: theme.selects.border.color.disabled,
    }),
    ...(selectProps.errored && {
      borderBottom: `2px solid ${theme.selects.border.color.error}`,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
  }),
  container: (provided, { theme }) => ({
    ...provided,
    lineHeight: `${theme.selects.height.xSmall}px`,
    outline: theme.selects.outline,
    boxShadow: 'none',
  }),
  valueContainer: (provided, { theme }) => ({
    ...provided,
    padding: theme.selects.padding.default,
  }),
  singleValue: (provided, { theme }) => ({
    ...provided,
    width: theme.selects.width.default,
    margin: 0,
    color: theme.selects.color.dark,
    // due to src/components/ui/__v2__/Table/components/DataTable/DataTable.js -> line 157
    whiteSpace: `${theme.selects.whiteSpace} !important`,
    overflowX: `${theme.selects.overflowX} !important`,
    textOverflow: `${theme.selects.textOverflow} !important`,
  }),
  multiValue: (provided, { theme }) => ({
    ...provided,
    borderRadius: theme.selects.border.radius,
    color: theme.selects.color.dark,
  }),
  multiValueLabel: (provided, { theme }) => ({
    ...provided,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: theme.selects.border.radius,
    borderTopLeftRadius: theme.selects.border.radius,
    padding: theme.selects.padding.xSmall,
    backgroundColor: theme.selects.backgroundColor.default,
  }),
  multiValueRemove: (provided, { theme }) => ({
    ...provided,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: theme.selects.border.radius,
    borderTopRightRadius: theme.selects.border.radius,
    cursor: theme.selects.cursor,
    backgroundColor: theme.selects.backgroundColor.default,
    color: theme.selects.color.default,
    transition: theme.selects.transition,
    '&:hover': {
      backgroundColor: theme.selects.color.error,
    },
  }),
  groupHeading: (provided, { theme }) => ({
    ...provided,
    borderBottom: `1px solid ${theme.selects.border.color.default}`,
    padding: '0 0px',
    margin: '0 7px',
  }),
  placeholder: (provided, { theme, isDisabled }) => ({
    ...provided,
    color: isDisabled
      ? theme.selects.color.disabled
      : theme.selects.color.secondary,
  }),
  dropdownIndicator: (provided, { theme, selectProps, isDisabled }) => ({
    ...provided,
    color: isDisabled ? theme.selects.color.disabled : theme.selects.color.dark,
    cursor: theme.selects.cursor,
    transition: theme.selects.transition,

    ...(selectProps.menuIsOpen && {
      transform: theme.selects.transform.rotate,
    }),
  }),
  clearIndicator: (provided, { theme }) => ({
    ...provided,
    cursor: theme.selects.cursor,
    color: theme.selects.color.default,
  }),
  // hide
  indicatorSeparator: () => ({}),
}

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${layout};
  ${space};
`

export const StyledSelect = styled.div.attrs(({ async, creatable }) => ({
  as: getSelectType({ async, creatable }),
}))`
  font-size: 14px;

  ${position};
  ${space};
  ${layout};
`

StyledSelect.defaultProps = {
  isSearchable: false,
  className: 'react-select-container',
  classNamePrefix: 'react-select',
}

StyledSelect.propTypes = {
  ...StyledPropTypes.width,
  ...StyledPropTypes.margin,
}
