import React, { useCallback, useMemo } from 'react'

import { API } from 'API'
import { UsePickerEmploymentTypesArgs } from 'API/EmploymentType/usePickerEmploymentTypes'
import { SelectOption } from 'Types/common'

import { Select } from 'components/ui/__v3__/Select'

import { employmentTypeToOption } from './helpers'

type SelectProps = {
  disabled?: boolean
  placeholder?: string
  value: SelectOption | null
  onSelect: (arg: SelectOption | null) => void
  onBlur: () => void
} // Note: clear this after Select is typed

export type EmploymentTypePickerProps = SelectProps &
  UsePickerEmploymentTypesArgs

export function EmploymentTypePicker({
  disabled,
  value,
  onSelect,
  withArchived = false,
  withWorkflows,
  ...rest
}: EmploymentTypePickerProps) {
  const {
    loadEmploymentTypes,
    employmentTypes,
    fetching,
  } = API.EmploymentType.picker({ withArchived, withWorkflows })

  const handleMenuOpen = useCallback(() => {
    if (employmentTypes.length > 0) return
    loadEmploymentTypes()
  }, [employmentTypes.length, loadEmploymentTypes])

  const defaultOptions = useMemo(
    () => employmentTypes.map(employmentTypeToOption),
    [employmentTypes],
  )

  return (
    <Select
      // @ts-ignore
      closeMenuOnSelect
      isClearable
      isDisabled={disabled}
      isLoading={fetching}
      isSearchable
      options={defaultOptions}
      value={value}
      withPortal
      onChange={onSelect}
      onMenuOpen={handleMenuOpen}
      {...rest}
    />
  )
}
