import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const CREATE_COMPANY = createAsyncAction('company/CREATE')
export const UPDATE_COMPANY_SETTINGS = createAsyncAction(
  'company/UPDATE_COMPANY_SETTINGS',
)
export const CREATE_LOCATION_TAG = createAsyncAction(
  'viewer/CREATE_LOCATION_TAG',
)

export const createCompany = (name, sectorId) => {
  return apiCall({
    endpoint: '/companies',
    method: 'POST',
    query: {
      include: 'subscriptions.products,subscriptions.plans',
      data: {
        attributes: {
          name,
        },
        type: 'companies',
        relationships: {
          sector: {
            data: {
              type: 'sectors',
              id: sectorId,
            },
          },
        },
      },
    },
    types: CREATE_COMPANY,
  })
}

export const createLocationTag = tag => (dispatch, getState) => {
  const { color, name } = tag
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: `/companies/${companyId}/location_tags`,
      types: CREATE_LOCATION_TAG,
      query: {
        data: {
          attributes: {
            name,
            color,
          },
        },
        include: 'company.locationTags',
      },
    }),
  )
}

export const updateCompanySettings = params => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/settings`,
      method: 'POST',
      query: {
        data: {
          attributes: {
            company_settings: {
              auto_shift_name: params.autoShiftName,
              allow_department_manager_mutual_approve:
                params.allowDepartmentManagerMutualApprove,
              allow_future_manual_timecard_approve:
                params.allowFutureManualTimecardApprove,

              calendar_start_day: params.calendarStartDay,

              early_clock_in: params.earlyClockIn,
              early_clock_in_limit: params.earlyClockInLimit,

              disable_employee_profile_edit: params.disableEmployeeProfileEdit,
              disable_employee_breaks: params.disableEmployeeBreaks,

              send_schedule_emails: params.sendScheduleEmails,
              show_wages_to_managers: params.showWagesToManagers,
            },
          },
        },
      },
      types: UPDATE_COMPANY_SETTINGS,
      payload: {
        companyId,
        settings: {
          ...params,
        },
      },
    }),
  )
}
