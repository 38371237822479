import { useMemo } from 'react'

import { useApolloQuery } from 'API/services/Apollo'

import { TimeEvaluationWorkflowStatusQuery } from './GraphQL'

type Args = {
  pollInterval: number
}

export function useTimeEvaluationWorkflowStatus({
  temporalRunId,
  temporalWorkflowId,
  pollInterval,
}: Gateway.QueryTimeEvaluationWorkflowStatusArgs['input'] & Args) {
  const { data, loading, error } = useApolloQuery<
    QueryData<'timeEvaluationWorkflowStatus'>,
    Gateway.QueryTimeEvaluationWorkflowStatusArgs
  >(TimeEvaluationWorkflowStatusQuery, {
    fetchPolicy: 'network-only',
    pollInterval,
    skip: !temporalRunId || !temporalWorkflowId,
    variables: {
      input: {
        temporalRunId,
        temporalWorkflowId,
      },
    },
  })

  const workflowStatus = useMemo(
    () => data?.timeEvaluationWorkflowStatus.workflowStatus,
    [data?.timeEvaluationWorkflowStatus.workflowStatus],
  )

  return {
    workflowStatus,
    loadingWorkflowStatus: loading,
    workflowStatusError: !!error,
  }
}
