import { useMemo } from 'react'

import { useApolloQuery } from 'API/services/Apollo'

import { ClassifiersByOffset } from './GraphQL'

export function useClassifiersByOffset({
  offset,
  limit,
  sorting,
}: {
  offset: number
  limit: number
  sorting: Gateway.QueryClassifiersByOffsetArgs['sorting']
}) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'classifiersByOffset'>,
    Gateway.QueryClassifiersByOffsetArgs
  >(ClassifiersByOffset, {
    variables: {
      paging: { offset, limit },
      sorting,
    },
    fetchPolicy: 'network-only',
  })

  const classifiers = useMemo(() => data?.classifiersByOffset?.rows ?? [], [
    data,
  ])

  const totalClassifiers = useMemo(
    () => data?.classifiersByOffset?.pageInfo?.total ?? 0,
    [data],
  )

  return {
    classifiers,
    totalClassifiers,
    loadingClassifiers: loading,
    refetchClassifiers: refetch,
  }
}
