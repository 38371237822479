import React, { useCallback } from 'react'

import { API } from 'API'

import { Select } from 'components/ui/__v3__/Select'
import { PickerProps } from 'components/ui/__v3__/Select/types'

import { formatOptionLabel } from 'helpers/areas'
import { createpDebounce } from 'helpers/debounce'

import { entityToOption } from 'services/Options'

const SEARCH_DEBOUNCE_TIMEOUT = 350

type Props = Omit<
  PickerProps,
  | 'async'
  | 'closeMenuOnSelect'
  | 'defaultOptions'
  | 'formatOptionLabel'
  | 'isLoading'
  | 'loadOptions'
  | 'withPortal'
  | 'onMenuOpen'
>

export function GroupsPicker({ isMulti, onChange, ...rest }: Props) {
  const {
    groups,
    groupsLoading,
    loadGroups,
    refetchGroups,
  } = API.Group.picker()

  const defaultOptions = groups.map(entityToOption) ?? []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadOptions = useCallback(
    createpDebounce(
      async (inputValue: string, callback: Function) => {
        const refetchedData = await refetchGroups(inputValue)
        const newOptions = refetchedData.map(entityToOption)

        callback(newOptions)
      },
      undefined,
      SEARCH_DEBOUNCE_TIMEOUT,
    ),
    [refetchGroups],
  )

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect={!isMulti}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptionLabel()}
      isLoading={groupsLoading}
      isMulti={isMulti}
      loadOptions={handleLoadOptions}
      withPortal
      onChange={onChange}
      onMenuOpen={loadGroups}
      {...rest}
    />
  )
}
