import { TimeBucketChildCoreWithParentAPIItem } from 'API/TimeBucket'

import { TimeBucketChildrenOption } from '../types'

export function timeBucketChildrenEntityToOption(
  entity: TimeBucketChildCoreWithParentAPIItem,
): TimeBucketChildrenOption {
  return {
    value: entity.id,
    label: entity.name,
    timeBucketChild: entity,
  }
}
