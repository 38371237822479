import React from 'react'

import { LayoutProps } from 'styled-system'

import { ShiftValidationConflictAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

import { i18n } from 'i18n'

import { Container } from './styles'

import { ShiftValidationConflictRuleConditions } from '../ShiftValidationConflictRuleConditions'
import { AdditionalLimitationsAndSettings } from '../ShiftValidationConflictRuleConditions/components/AdditionalLimitationsAndSettings'
import { ShiftValidationConflictTypeIcon } from '../ShiftValidationConflictTypeIcon'

type Props = LayoutProps & {
  shiftConflict: ShiftValidationConflictAPIItem
}

export function ShiftValidationConflictItem({ shiftConflict }: Props) {
  return (
    <Container>
      <ShiftValidationConflictTypeIcon
        conflictType={shiftConflict.conflictType}
        size={18}
      />{' '}
      <b>
        {i18n(
          `workflowBuilder.palette.templates.${shiftConflict.rule.ruleType}.title`,
        )}
        :
      </b>{' '}
      <ShiftValidationConflictRuleConditions
        conflictType={shiftConflict.conflictType}
        rule={shiftConflict.rule}
      />{' '}
      <AdditionalLimitationsAndSettings rule={shiftConflict.rule} />
    </Container>
  )
}
