import { DateTime } from 'luxon'
import qs from 'qs'

import { isEmpty } from 'lodash'

import { EvaluatedTimesQueryParams } from 'pages/EmployerApp/EvaluatedTime/hooks/types'

import Utils from 'services/Utils'

export function evaluatedTimesQueryParamsToFilter(
  filters: EvaluatedTimesQueryParams['filters'],
): Gateway.QueryEvaluatedTimesByCursorArgs['filter'] {
  return {
    date: { between: [filters?.period.from, filters?.period.to] },
    ...(!isEmpty(filters.employees) && {
      employeeId: Utils.GraphQL.inFilter(
        filters?.employees.map(employee => employee.value),
      ),
    }),
    ...(filters.timeBucket?.value && {
      timeBucketId: Utils.GraphQL.eqFilter(filters.timeBucket.value),
    }),
    ...(filters.earningCode && {
      earningCode: Utils.GraphQL.ilikeFilter(filters.earningCode),
    }),
    ...(!isEmpty(filters.changeTypes) && {
      changeType: Utils.GraphQL.inFilter(filters.changeTypes.map(e => e.value)),
    }),
  }
}

export function evaluatedTimesQueryParamsToFilterToQueryString(
  locale: string,
  filter: EvaluatedTimesQueryParams['filters'],
): string {
  const query = qs.stringify(
    {
      locale,
      filter: {
        date: Utils.JsonApi.betweenFilter(filter.period),
        ...(!isEmpty(filter.employees) && {
          employee_id: Utils.JsonApi.inFilter(
            filter.employees.map(employee => employee.value),
          ),
        }),
        ...(!isEmpty(filter.timeBucket) && {
          time_bucket_id: Utils.JsonApi.eqFilter(filter.timeBucket!.value),
        }),
        ...(!isEmpty(filter.earningCode) && {
          earning_code: Utils.JsonApi.iLike(filter.earningCode!),
        }),
        ...(!isEmpty(filter.changeTypes) && {
          change_type: Utils.JsonApi.inFilter(
            filter.changeTypes.map(employee => employee.value),
          ),
        }),
      },
    },
    { skipNulls: true, arrayFormat: 'brackets' },
  )

  return query
}

export function evaluatedTimesQueryParamsToFilterToMicropayQueryString(
  locale: string,
  filter: EvaluatedTimesQueryParams['filters'],
) {
  let toDate = null

  if (filter.period.to) {
    // Note: end date must be always sunday
    toDate =
      DateTime.fromISO(filter.period.to).weekday === 7
        ? DateTime.fromISO(filter.period.to).toISODate()
        : DateTime.fromISO(filter.period.to).set({ weekday: 7 }).toISODate()
  }

  const periodFilter = {
    ...filter.period,
    to: toDate,
  }

  const query = qs.stringify(
    {
      locale,
      filter: {
        date: Utils.JsonApi.betweenFilter(periodFilter, {
          includeEndDate: false,
        }),
        ...(!isEmpty(filter.changeTypes) && {
          change_type: Utils.JsonApi.inFilter(
            filter.changeTypes.map(changeType => changeType.value),
          ),
        }),
        ...(!isEmpty(filter.employees) && {
          employee_id: Utils.JsonApi.inFilter(
            filter.employees.map(employee => employee.value),
          ),
        }),
      },
    },
    { skipNulls: true, arrayFormat: 'brackets' },
  )
  return query
}

export function evaluatedTimesQueryParamsToFilterToSyncPayload(
  filters: EvaluatedTimesQueryParams['filters'],
): Gateway.MutationStartEvaluatedTimesSyncArgs {
  return {
    filter: {
      changeType: Utils.GraphQL.inFilter(
        filters.changeTypes.map(employee => employee.value),
      ),
      employeeId: Utils.GraphQL.inFilter(
        filters.employees.map(employee => employee.value),
      ),
    },
    period: { between: [filters.period.from, filters.period.to] },
  }
}
