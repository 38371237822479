import { useSchedulesByCursor } from './useSchedulesByCursor'
import { useSchedulesCount } from './useSchedulesCount'
import { useSchedulesGardaListView } from './useSchedulesGardaListView'
import { useShiftsWeeklySchedulesByCursor } from './useShiftsWeeklySchedulesByCursor'

export const ScheduleAPI = {
  byCursor: useSchedulesByCursor,
  byCursorForShiftsWeekly: useShiftsWeeklySchedulesByCursor,
  byCursorForGardaDaily: useSchedulesGardaListView,
  schedulesCount: useSchedulesCount,
}
