import styled from 'styled-components'
import { layout, LayoutProps } from 'styled-system'

export const Container = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]}px;
  padding: ${({ theme }) => theme.space[2]}px;
  max-height: 250px;

  ${layout}
`

export const ShiftConflictContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space[1]}px;
  overflow: auto;
`
