import styled from 'styled-components'

export const ClassifierLabelContainer = styled.div`
  max-width: 270px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ClassifierPopoverContent = styled.div`
  max-width: 500px;
  word-break: break-all;
`
