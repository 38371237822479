import { Branch, CustomLeave, Department, Employee, Job } from 'Types/app'
import { BaseEntity, SelectOption } from 'Types/common'

import get from 'lodash/get'
import has from 'lodash/has'
import map from 'lodash/map'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { buildName } from 'helpers/employee'

// TODO: enchance this function to be more generic (valueToOption)
export const stringToOption = (value: string): SelectOption => ({
  value,
  label: value,
})

export function labelDayToOption(labelDay: CustomLeave) {
  return {
    label: labelDay.name,
    value: labelDay.id,
    data: {
      name: labelDay.name,
      paid: labelDay.paid,
      referenceYearEndRecurrence: labelDay.referenceYearEndRecurrence,
      effectiveDates: labelDay.effectiveDates,
    },
  }
}

export function locationToOption(location: Branch) {
  return {
    id: location.id,
    value: location.id,
    label: location.name,
    location: thinLocation(location),
  }
}

export function departmentToOption(department: Department) {
  return {
    id: department.id,
    value: department.id,
    label: department.name,
    department,
  }
}

export function jobToOption(job: Job) {
  return {
    id: job.id,
    value: job.id,
    label: job.name,
    job,
  }
}

export function employeeToOption(employee: Employee) {
  return {
    id: employee.id,
    value: employee.id,
    label: buildName(employee),
    employee,
  }
}

export function staffMemberToOption(staff: $TSFixMe) {
  return {
    id: staff.id,
    value: staff.id,
    label: buildName(staff.profile),
    staff,
  }
}

export function jobEmployeeToOption(jobEmployee: $TSFixMe) {
  return {
    id: jobEmployee.id,
    value: jobEmployee.id,
    label: buildName(get(jobEmployee, ['employee'])),
    jobEmployee,
  }
}

export function classifierToOption(classifier: $TSFixMe) {
  return {
    id: classifier.id,
    value: classifier.id,
    label: classifier.name,
    classifier,
  }
}

export function customCVFieldToOption(customCvField: Gateway.CustomCvField) {
  return {
    id: customCvField.id,
    value: customCvField.name,
    label: customCvField.name,
    customCvField,
  }
}

export function entityToOption<T extends BaseEntity>(entity: T): SelectOption {
  return {
    value: entity.id,
    label: entity.name,
  }
}

export function optionToId(option: $TSFixMe) {
  return {
    id: option.value,
  }
}

export function reduceToOption(option: $TSFixMe) {
  return {
    value: option?.value,
    label: option?.label,
    id: option?.value,
  }
}

export function getAreasOptionsMappers() {
  const locationsMapper = locationToOption
  const departmentsMapper = departmentToOption
  const jobsMapper = jobToOption

  return {
    locationsMapper,
    departmentsMapper,
    jobsMapper,
  }
}

export function thinLocation(location: $TSFixMe) {
  return omit(
    {
      ...location,
      company: has(location, 'company') ? pick(location.company, ['id']) : null,
      employee: has(location, 'employee')
        ? pick(location.employee, ['id'])
        : null,
      departments: has(location, 'departments')
        ? map(location.departments, department =>
            pick(department, ['id', 'exportCode', 'name', 'jobs']),
          )
        : null,
      jobs: has(location, 'jobs')
        ? map(location.jobs, job => pick(job, ['id', 'exportCode', 'name']))
        : null,
      managers: has(location, 'managers')
        ? map(location.managers, manager => omit(manager, ['company']))
        : null,
      prayerSettings: has(location, 'prayerSettings')
        ? omit(location.prayerSettings, ['branch'])
        : null,
    },
    ['manager'],
  )
}

export type PayFrequencyOption = SelectOption & {
  data: Pick<Gateway.PayFrequency, 'type' | 'startDate'>
}

export const payFrequencyEntityToOption = (
  payFrequency: Pick<
    Gateway.PayFrequency,
    'id' | 'name' | 'type' | 'startDate'
  >,
): PayFrequencyOption => ({
  value: payFrequency.id,
  label: payFrequency.name,
  data: { type: payFrequency.type, startDate: payFrequency.startDate },
})
