import React, { useCallback, useEffect, useState } from 'react'

import { ScheduleAPI, ShiftValidationEssentialAPIItem } from 'API'

import { Flex, SpacedColumn } from 'components/ui/__v2__/Grid'
import { LoadMoreStatus } from 'components/ui/__v3__'
import { Spinner } from 'components/ui/Spinner'

import { ScheduleState } from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import { i18n } from 'i18n'

import { useShiftAssignmentContext } from '../../../context'
import { getShiftConflictsByEmployeeId } from '../../../helpers'
import { useShiftValidation } from '../../../hooks'
import { SectionTitle, SubtleText } from '../../../styles'
import { ExistingAssignment } from '../components'
import { AssignmentsList } from '../styles'

type Props = {
  shift: Gateway.Shift
  hasShiftValidation: boolean
  onChangeDeferredLoading: (value: boolean) => void
}

export function ExistingAssignments({
  shift,
  hasShiftValidation,
  onChangeDeferredLoading,
}: Props) {
  const [shiftValidations, setShiftValidations] = useState<
    ShiftValidationEssentialAPIItem[]
  >([])

  useEffect(() => {
    setShiftValidations([])
  }, [shift])

  const {
    selectedShiftJob,
    missingAssignments,
    draftUnassign,
    isDraftUnassigned,
    revertDraftUnassignment,
  } = useShiftAssignmentContext()

  const { validatingShift, validateShift } = useShiftValidation()

  const {
    schedules,
    loadingInitial,
    pageInfo,
    loadMore,
  } = ScheduleAPI.byCursor(
    {
      shiftJobId: selectedShiftJob?.id ?? '',
      paging: { limit: PAGE_SIZE['10'] },
    },
    {
      skip: !selectedShiftJob,
      // NOTE: validation is executed exactly each time after the schedules are loaded
      onCompleted: async () => {
        if (schedules.length === 0 || !hasShiftValidation) return

        onChangeDeferredLoading(true)

        const employeeIds = schedules.map(schedule =>
          String(schedule.employee.id),
        )
        const validations = await validateShift({ employeeIds, shift })
        setShiftValidations(validations)

        onChangeDeferredLoading(false)
      },
    },
  )

  const handleLoadMoreClick = useCallback(async () => {
    if (!pageInfo?.endCursor) return
    loadMore(pageInfo?.endCursor)
  }, [loadMore, pageInfo?.endCursor])

  const hasMore = pageInfo?.hasNextPage ?? false

  const handleGetShiftConflictsByEmployeeId = useCallback(
    (employeeId: number) =>
      getShiftConflictsByEmployeeId(employeeId, shiftValidations),
    [shiftValidations],
  )

  const hasEmptySlots = missingAssignments > 0

  // NOTE: since we have the same ruleset for all validations
  //       we can take the ruleset from any validation
  const rulesetId =
    shiftValidations.length > 0 ? shiftValidations[0].rulesetId : ''

  const renderContent = useCallback(() => {
    if (loadingInitial)
      return (
        <Flex alignItems="center" justifyContent="center" width="100%">
          <Spinner primary size={28} />
        </Flex>
      )
    return (
      <>
        {schedules.length > 0 ? (
          <AssignmentsList>
            {schedules.map(schedule => (
              <ExistingAssignment
                beingUnassigned={isDraftUnassigned(schedule)}
                canReassignBack={hasEmptySlots}
                firstName={schedule.employee.user.profile.firstName}
                key={schedule.id}
                lastName={schedule.employee.user.profile.lastName}
                published={schedule.state === ScheduleState.Published}
                rulesetId={rulesetId}
                shiftConflicts={handleGetShiftConflictsByEmployeeId(
                  schedule.employee.id,
                )}
                validatingShift={validatingShift}
                onRestoreAssignmentClick={() =>
                  revertDraftUnassignment(schedule)
                }
                onUnassignClick={() => draftUnassign(schedule)}
              />
            ))}
            <LoadMoreStatus
              fetchingMore={loadingInitial}
              hasMore={hasMore}
              onClick={handleLoadMoreClick}
            />
          </AssignmentsList>
        ) : (
          <SubtleText>
            {i18n('shiftAssign.existingAssignments.nooneAssignedYet')}
          </SubtleText>
        )}
      </>
    )
  }, [
    draftUnassign,
    hasEmptySlots,
    hasMore,
    isDraftUnassigned,
    loadingInitial,
    revertDraftUnassignment,
    schedules,
    validatingShift,
    handleLoadMoreClick,
    handleGetShiftConflictsByEmployeeId,
    rulesetId,
  ])

  return (
    <SpacedColumn>
      <SectionTitle>
        {i18n('shiftAssign.existingAssignments.title')}
      </SectionTitle>
      {renderContent()}
    </SpacedColumn>
  )
}
