import { EmployeePickerApiReturn } from 'API/Employee/useLazyEmployeesByCursor/types'

import { omit } from 'lodash'

import Utils from 'services/Utils'

import {
  EmployeePickerInternalOption,
  EmployeePickerReturnOption,
} from './types'

export function employeeMemberToOption(
  employee: EmployeePickerApiReturn,
): EmployeePickerInternalOption {
  return {
    value: employee.id,
    // Note: only used for matching purposes
    timesheetsExportCode: employee.timesheetsExportCode ?? '',
    label: Utils.User.buildFullName(employee.profile),
  }
}

export function exposeEmployeeOption(
  employeePickerInternalOption: EmployeePickerInternalOption,
): EmployeePickerReturnOption {
  return omit(employeePickerInternalOption, 'timesheetsExportCode')
}

export function matchNameOrCode(
  option: EmployeePickerInternalOption,
  rawInput?: string,
): boolean {
  if (!rawInput) return true

  const input = rawInput.toLocaleLowerCase()

  const nameMatches = option.label.toLocaleLowerCase().includes(input)

  const codeMatches = option.timesheetsExportCode
    .toLocaleLowerCase()
    .includes(input)

  return nameMatches ?? codeMatches
}
