import React from 'react'

import { LayoutProps } from 'styled-system'

import { ShiftValidationConflictAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

import { Divider } from 'components/ui/__v2__/Divider'

import { Container, ShiftConflictContainer } from './styles'

import { ShiftValidationConflictItem } from '../ShiftValidationConflictItem'
import { ShiftValidationConflictRulesetLink } from '../ShiftValidationConflictRulesetLink'

type Props = LayoutProps & {
  shiftConflicts: ShiftValidationConflictAPIItem[]
  rulesetId: string
}

export function ShiftValidationConflictRuleInfo({
  shiftConflicts,
  rulesetId,
  ...rest
}: Props) {
  return (
    <Container {...rest}>
      <ShiftValidationConflictRulesetLink rulesetId={rulesetId} />

      <ShiftConflictContainer>
        {shiftConflicts.map((shiftConflict, index) => (
          <React.Fragment key={`${shiftConflict.rule.id}-${index}`}>
            <ShiftValidationConflictItem shiftConflict={shiftConflict} />

            {index !== shiftConflicts.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </ShiftConflictContainer>
    </Container>
  )
}
