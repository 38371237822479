import { AvailableEmployeesForShiftJobCheck } from 'constants/gatewayGraphQL'

import { UnavailabilityReasons } from '../../types'

export function reasonToOptions(
  reason: UnavailabilityReasons,
): Gateway.AvailableEmployeesForShiftJobOptions {
  switch (reason) {
    case UnavailabilityReasons.AvailabilityConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.True,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        useOr: false,
      }
    case UnavailabilityReasons.AvailableButShiftConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.False,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.True,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        useOr: false,
      }
    case UnavailabilityReasons.AvailableBustTimeoffConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.False,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.True,
        useOr: false,
      }
    case UnavailabilityReasons.Whatever:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.True,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.True,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.True,
        useOr: true,
      }
    case UnavailabilityReasons.ShiftConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.True,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        useOr: false,
      }
    case UnavailabilityReasons.TimeoffConflict:
      return {
        availabilityConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.Skip,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.True,
        useOr: false,
      }
    default:
      throw new Error(`Unknown reason: ${reason}`)
  }
}
