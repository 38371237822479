// old methods
import { AutoAssignStatus } from './types'
import { useAutoAssignStatus as getStatus } from './useAutoAssignStatus'
import { useLaunchAutoAssignTask as launch } from './useLaunchingAutoAssignTask'

export const AutoSchedulingTaskAPI = {
  // old methods
  launch,
  getStatus,
  AutoAssignStatus,
}
